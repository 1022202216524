import React, { useEffect, useState } from 'react';
import MyProfile from '../../Component/Sections/MyProfile.jsx';
import MyOrder from '../../Component/Sections/MyOrder.jsx';
import MyFavourite from '../../Component/Sections/MyFavourite.jsx';
import MyOffers from '../../Component/Sections/MyOffers.jsx';
import MyAddress from '../../Component/Sections/MyAddress.jsx';
import AlertBox from '../../Component/AlertBox/AlertBox.jsx';
import Account from '../../../API/API_SERVISES/Account.js';
import { useNavigate, useLocation } from "react-router-dom";
import MyPrescription from '../../Component/Sections/MyPrescription.jsx';
import ModelSavedPrescription from '../../Component/Sections/ModelSavedPrescription.jsx';


const AdminPage = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Scroll to the top of the page when the App component is mounted
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();
  const { tabName } = location.state || {};

  const navigate = useNavigate();

  const [alert, setAlert] = useState(false);
  const alertbox = () => {
    setAlert(!alert)
  }

  const logout = () => {
    localStorage.removeItem('accountLogin');

    navigate("/");
    window.location.reload();
  };

  const [selectedOption, setSelectedOption] = useState(tabName || "My Profile");

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const [accountData, setAccountData] = useState([]);

  useEffect(() => {
    var loginDataString = localStorage.getItem("accountLogin");
    var loginData = JSON.parse(loginDataString);
    // console.log(loginData.d1Id);
    const fetchAccountData = async () => {
      try {
        var data = await Account.getAccountById(loginData.d1Id);
        console.log(data.item1[0]);
        if (data) {
          setLoading(false)
          setAccountData(data.item1[0]);
        }
      }
      catch { }
    };
    fetchAccountData()
  }, []);


  const renderSelectedOption = () => {
    switch (selectedOption) {
      case 'My Profile':
        return <MyProfile accountData={accountData} />;
      case 'My Order':
        return <MyOrder accountData={accountData} />;
      case 'My Favourite':
        return <MyFavourite />;
      case 'My OFFERS':
        return <MyOffers />;
      case 'My Address':
        return <MyAddress />;
      case 'My Prescriptions':
        // return <MyPrescription />;
        return <ModelSavedPrescription />;
      default:
        return null;
    }
  };

  return (
    <div className='admin-page'>
      {/* <div className='admin-page-header'></div> */}
      <div className='admin-page-content admin-padding-cart5'>
        <div className='admin-page-left-cart5'>
          <div className={`admin-options-cart5 ${selectedOption === 'My Profile' ? 'active' : ''}`} onClick={() => handleOptionClick('My Profile')}><i className="fa-regular fa-user fs-bs"></i> MY PROFILE</div>
          <div className={`admin-options-cart5 ${selectedOption === 'My Order' ? 'active' : ''}`} onClick={() => handleOptionClick('My Order')}> <i className="fa-solid fa-glasses fs-bs" ></i> MY ORDER</div>
          <div className={`admin-options-cart5 ${selectedOption === 'My Address' ? 'active' : ''}`} onClick={() => handleOptionClick('My Address')}> <i className="fa-solid fa-location-dot fs-bs"></i>ADDRESS BOOK</div>
          <div className={`admin-options-cart5 ${selectedOption === 'My Favourite' ? 'active' : ''}`} onClick={() => handleOptionClick('My Favourite')}> <i className="fa-regular fa-heart fs-bs"></i> MY WISHLIST</div>
          <div className={`admin-options-cart5 ${selectedOption === 'My Prescriptions' ? 'active' : ''}`} onClick={() => handleOptionClick('My Prescriptions')}> <i class="fa-solid fa-eye fs-bs"></i> MY PRESCRIPTION</div>
          {/* <div className={`admin-options-cart5 ${selectedOption === 'My OFFERS' ? 'active' : ''}`} onClick={() => handleOptionClick('My OFFERS')}> <i className="fa-brands fa-buffer fs-bs"></i> My OFFERS </div> */}
          <div onClick={alertbox} className='admin-options-cart5' >
            <i className="fa-solid fa-arrow-right-from-bracket fs-bs"></i>Logout  </div>
        </div>
        <div className='admin-page-right-cart5 flex-admin'>
          <div className='admin-right-section admin-right-sec-cart5'>
            {renderSelectedOption()}
          </div>
        </div>
      </div>
      {alert && (
        <AlertBox alertname="Are You Sure You Want to logout" alertbox={logout} close={alertbox} />
      )}

    </div>

  );
};

export default AdminPage;
