import React, { useContext, useEffect, useRef, useState } from 'react'
import Header from '../header/Header'
import storeimg from "../../../Assets/Image_lc/review.png"
import BookAppoientment from '../BookAppoientment/BookAppoientment'
import Footer from '../Footer/Footer'
import { Icon } from '@iconify/react/dist/iconify.js'
import Productcard from './Productcard/Productcard'
import MyContext from '../../../MyContext'
import card1 from "../../../Assets/Image_lc/card-images/l2c store.jpg";

const StoreHome = () => {
    const { companyData } = useContext(MyContext);
    const [selecteditem, setselecteditem] = useState(null)
    const [modal, setmodal] = useState(false)
    const [selectedtab, setselectedtab] = useState(0)
    const [DeviceType, setDeviceType] = useState(null)
    const [dynamicplaceholder, setdynamicplaceholder] = useState("sdasd")

    const review = [
        {
            message: "Wow so nice I so happy this product",
            icon: "",
            name: "Review"
        },
        {
            message: "Wow so nice I so happy this product",
            icon: "",
            name: "Review"
        },
        {
            message: "Wow so nice I so happy this product",
            icon: "",
            name: "Review"
        },
        {
            message: "Wow so nice I so happy this product",
            icon: "",
            name: "Review"
        }
    ]


    const storesData = [
        {
            name: "Kenya",
            address:
                "Shop 5, next to Prime bank, doctors park,3rd parklands ,nairobi kenya",
            hours: "09:00 AM to 06:00 PM",
            phone: `${companyData?.mobileNo || "N/A"}`,
            rating: "4.5",
            reviews: "522",
            image:
                ""
            || card1 ,
        },
        // {
        //     name: "Jawahar Nagar",
        //     address:
        //         "Shop No 2, UA/29 Bungalow Road, Opposite Hansraj Girls Hostel, Jawahar Nagar, Delhi, 110007",
        //     hours: "11:00 AM to 10:00 PM",
        //     phone: "+91 8929811423",
        //     rating: "4.8",
        //     reviews: "1622",
        //     image:
        //         "https://www.lenskart.com/stores/_next/image?url=https%3A%2F%2Fmy-lkstore.lenskart.com%2Fstore_locator_image%2FLKST423%2F2.jpeg&w=1200&q=75",
        //     freeGift: "Free Anti Fog Spray",
        //     isOpen: true,
        // },
        // {
        //     name: "Paschim Vihar",
        //     address: "Shop No A3/182, Ground Floor, Paschim Vihar, Kenya, 110063",
        //     hours: "11:00 AM to 09:30 PM",
        //     phone: "+91 7428891110",
        //     rating: "4.8",
        //     reviews: "981",
        //     image:
        //         "https://www.lenskart.com/stores/_next/image?url=https%3A%2F%2Fmy-lkstore.lenskart.com%2Fstore_locator_image%2FLKST110%2FWhatsApp%2520Image%25202019-12-20%2520at%252011.12.43%2520AM.jpeg&w=1920&q=75",
        //     freeGift: "",
        //     isOpen: true,
        // },
    ];
    const device = navigator.userAgent.toLowerCase()
    console.log(device)
    useEffect(() => {
        const checkDeviceType = () => {
            const width = window.innerWidth;
            if (width <= 768) {
                setDeviceType("Mobile");
            } else if (width > 768 && width <= 1200) {
                setDeviceType("Tablet");
            } else {
                setDeviceType("Desktop/Laptop");
            }
        };

        checkDeviceType();
        window.addEventListener("resize", checkDeviceType);

        return () => window.removeEventListener("resize", checkDeviceType);
    }, [])

    useEffect(() => {
        debugger
        const inputeliment = document.getElementById('searchproduct')
        console.log(DeviceType)
        if (DeviceType == "Mobile" && inputeliment != null) {
            setdynamicplaceholder("Search products")
            inputeliment.placeholder = "Search products";
        } if (DeviceType == "Desktop/Laptop" && inputeliment != null) {
            setdynamicplaceholder("Search products, brands, and categories")
            inputeliment.placeholder = "Search products, brands, and categories"
        }
    }, [DeviceType, selectedtab]);

    const productdata = [
        {
            name: "Lens2cart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lens2cart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lens2cart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lens2cart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lens2cart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lens2cart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lens2cart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lens2cart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lens2cart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lens2cart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lens2cart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lens2cart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lens2cart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lens2cart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lens2cart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lens2cart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lens2cart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lens2cart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lens2cart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lens2cart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lens2cart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lens2cart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        }
    ]
    const tabcontent = useRef([
        {
            title: "Review",
            data: (
                <div className="container-product-card">
                    <div className="reviews-section">
                        <h1 className="reviews-title">Reviews</h1>
                        <div className="reviews-list">
                            {review.map((item) => {
                                return (
                                    <div className="review-card">
                                        <p className="review-text">{item.message}</p>

                                        <h2 className="review-name">
                                            <img className="review-image" src={storeimg} alt="Review" />
                                            {item.name}
                                        </h2>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    <div className="about-store">
                        <h1 className="about-store-title">About Lens2cart Optical Store at Kenya</h1>
                        <p className="about-store-text">
                            Lens2cart Kenya eyewear store is located at Kenya. It is one of the best optical stores in Kenya with immense number of Eyeglasses, sunglasses & contact lens options to choose from. The store offers free eye check up to its customers which is performed by certified Optometrist. The free eye test service is available across all stores in India. If you are searching for the best optician near you then Lens2cart Kenya is the perfect choice for you. The address of this store is Property Bearing No 14, Ground Floor, khasra No 619/10 & Old No 619/21, Situated in Extended Abadi of Lal Dora of Village Kenya, Tehsil Hauz Khas, Mehrauli, Kenya, 110074, Kenya, Kenya, Delhi - 110074.
                        </p>
                    </div>

                    <div className="nearby-stores">
                        <h2 className="nearby-stores-title">Other Store Nearby</h2>

                        <div className="store-list">
                            {storesData.map((item) => {
                                return (
                                    <div className="store-item" key={item.name}>
                                        <h2 className={`store-item-name`}>{item.name}</h2>
                                        <p className={`para-store-item`}>{item.address}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )
        },
        // {
        //     title: "Product",
        //     data: (
        //         <>
        //             <div className={`container-product-card`}>
        //                 {/* -------searchbox-------- */}
        //                 <div className={'searchbox'}>
        //                     <span className={`searchbox-span`}>
        //                         <Icon icon="weui:search-outlined" style={{ color: "#212529" }} />
        //                     </span>
        //                     <input className={`searchbox-input`} id='searchproduct' name='searchproduct' type='text' placeholder={"Search any product"} />
        //                 </div>


        //                 <div className={`storehome-all-product`}>
        //                     {
        //                         productdata.map((product) => {
        //                             return (
        //                                 <div style={{ margin: '10px' }}>
        //                                     <Productcard product={product} />
        //                                 </div>
        //                             )
        //                         })
        //                     }
        //                 </div>
        //             </div>
        //         </>
        //     )
        // }
    ])



    useEffect(() => {
        const location = window.location
        const lastSegment = location.pathname.split('/').pop();
        const decodedString = decodeURIComponent(lastSegment);
        const selectedsetore = storesData.find((item) => item.name == decodedString)
        console.log(window.location)
        console.log(decodedString)
        console.log(selectedsetore)
        if (selectedsetore) {
            setselecteditem(selectedsetore)
        }
    }, []);

    const box = (top = "Hours", bottom = "09:00 AM - 06:00 PM") => {

        return (
            <>
                <div className="hours-container">
                    <p>{top}</p>
                    <p className="hours-text">{bottom}</p>
                </div>

            </>
        )
    }

    const Allstore = [
        {
            name: "Kenya",
            iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d530.4185689890259!2d36.82102424959869!3d-1.261360800000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f171bd8368527%3A0xef1bf33a94c0c87!2sPrime%20Bank!5e1!3m2!1sen!2sin!4v1733835044422!5m2!1sen!2sin"
        },
        {
            name: "Jawahar Nagar",
            iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3500.3115580091235!2d77.20551207457478!3d28.68032538191038!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd6543d80cbf%3A0x5b5300227eabc7a3!2sLenskart.com%20at%20Kamla%20Nagar%2C%20Delhi!5e0!3m2!1sen!2sin!4v1731416682704!5m2!1sen!2sin"
        },
        // {
        //     name: "Paschim Vihar",
        //     iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3500.6613995350012!2d77.10373487457433!3d28.66985548238647!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a27af074692537b%3A0x5c3cc90ce00cd942!2sLenskart.com%20at%20Paschim%20Vihar!5e0!3m2!1sen!2sin!4v1731479016661!5m2!1sen!2sin"
        // }

    ]

    const handleappoientment = () => {
        setmodal(true);
    }

    const [map, setmap] = useState(null)
    useEffect(() => {
        console.log(selecteditem);

        const mapname = Allstore.find((item) => item.name == selecteditem?.name)
        setmap(mapname?.iframe)

    }, [selecteditem]);
    return (
        <>
            <Header />

            <div className={`storehomepage`}>
                <div className="section-container">
                    <div className="image-container">
                        <img
                            src={selecteditem?.image}
                            alt="Selected"
                            className="image-container-img"
                        />
                    </div>

                    <div className="content-container">
                        <h1 className="title-store-locator">{selecteditem?.name}</h1>
                        <p className="address">{selecteditem?.address}</p>
                        <div className="box-container">
                            {box()}
                            {box("Services", "Eye Test, Repair, Purchase")}
                        </div>

                        {/* ---------EndPart----------------- */}
                        <div className="additional-section">
                            <div className="offer-banner">
                                <p>Redeem FREE Anti Fog Spray at next step!</p>
                            </div>

                            <div className="button-container">
                                <button onClick={()=> setmodal(true)} className="button book-button">

                                    Book Appointment
                                </button>
                                <button className="button direction-button">
                                <i class="fa-solid fa-location-arrow"></i>
                          
                                    Direction
                                </button>
                                <button className="button call-button">
                                <i class="fa-solid fa-phone-volume"></i>
                                   
                                    Call
                                </button>
                                <button className="button help-button">
                                    <i class="fa-brands fa-whatsapp"></i>

                                   
                                    Need Help?
                                </button>

                                {
                                    // -------show on mobile devices---------------------
                                    <div className={`mobile-book`}>
                                        <div>
                                            <p>Redeem FREE Anti Fog Spray on booking</p>
                                        </div>
                                        <button>
                                            Book Appointment
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {/* ------last---------- */}
                <div className={`lastmsain`}>
                    {/* ------Tab-button------ */}
                    <div className={`tab-content`}>
                        <div className={`tab-button`}>
                            <button onClick={() => setselectedtab(0)} className={`tab-single-button ${selectedtab == 0 ? 'active1' : null}`}>Review</button>
                            {/* <button onClick={() => setselectedtab(1)} className={`tab-single-button ${selectedtab == 1 ? 'active1' : null}`}>Product</button> */}
                        </div>
                        {tabcontent.current[selectedtab].data}
                    </div>
                    <div className="map-container">
                        <iframe
                            src={map}
                            width="100%"
                            height="400px"
                            allowFullScreen
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade">
                        </iframe>

                        <div className={`antifog-spray`}>
                            <h1>
                                Get Free Eye Test & Anti Fog Spray
                            </h1>
                            <p className={`para`}>Book free eye test appointment at your nearby Len2cart store</p>

                            <button onClick={handleappoientment} className="store-appointment-button">
                                <a href="#_" className="store-appointment-link">
                                    Book an Appointment
                                </a>
                            </button>
                        </div>
                    </div>

                    {modal ? <BookAppoientment modal={modal} setmodal={setmodal} /> : null}
                </div>
            </div>

            <Footer />
        </>
    )
}

export default StoreHome