import axios from "../axiosConfig"; // Adjust the import path accordingly

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const Payment = {

    // ************************* Insert Payment Gateway ***************************
    insertPayment: async (data) => {

        try {
            const apiUrl = `${WebApiUrl}/api/PaymentGateway/PaymentGateway`;

            const response = await axios.post(apiUrl, {
                ord1Id: Number(data.ord1Id),
                orderNo: data.orderNo,
                date: data.date,
                orderRefNo: data.orderRefNo,
                amount: parseFloat(data.amount),
                apiId: Number(data.apiId),
                apiName: data.apiName,
                pgOrderId: data.pgOrderId,
                pgOrderStatus: data.pgOrderStatus,
                pgTrackingId: data.pgTrackingId,
                pgBankRefNo: data.pgBankRefNo,
                pgFailureMessage: data.pgFailureMessage,
                pgPaymentMode: data.pgPaymentMode,
                pgCardName: data.pgCardName,
                pgStatusCode: data.pgStatusCode,
                pgStatusMessage: data.pgStatusMessage,
                transactionDetail: data.transactionDetail,
                remarks: data.remarks,
            }, {
                withCredentials: true,
                credentials: 'include',
            });
            console.log("Response:", response.data);
            return response.data;

        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },

    // *********************** update payment partially ********************

    updatePaymentGatewayPartially: async (data, paymentGatewayId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/PaymentGateway/UpdatePaymentGatewayPartially?paymentGatewayId=${paymentGatewayId}`;
            console.log(apiUrl);
            const response = await axios.patch(apiUrl, data, {
                withCredentials: true,
                credentials: 'include',
            });
            console.log("Response:", response.data);
            return response.data.isSuccess
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again. updateOrderPartially");
            }
        }
    },


};

export default Payment;
