import axios from "../axiosConfig"; // Adjust the import path accordingly
//import AccountGroupApi from './AccountGroup';
//import StateInfoApi from './StateInfo';
//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);


const User = {
  loginUser: async (data) => {
    // // debugger
    // console.log(data)
    try {
      const apiUrl = `${WebApiUrl}/api/UserInfo/login`;
      const response = await axios.post(apiUrl, {
        compCode: data.compCode,
        userName: data.userName,
        password: data.password,
        comClientRefId: "string",
      }, {
        withCredentials: true,
      });

      console.log("Response:", response.data);

      if (response.data.isSuccess) {
        return true
      } else {
        alert("Incorrect username or password");
      }
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Authentication failed: Incorrect username or password");
      } else {
        alert("An error occurred during login. Please try again.");
      }
    }
    //AccountGroupApi.GetAccountGroups(setMainDataCallback);
    //StateInfoApi.GetState(setStateInfoCallback);
  },
  // ************************* Company **********************************
  GetCompany: async () => {
    try {

      const apiUrl = `${WebApiUrl}/api/Company/GetCompanies`;
      const response = await axios.post(apiUrl, {}, {
        withCredentials: true,
      });
      console.log(apiUrl);
      console.log('Response:', response.data.result);
    } catch (error) {
      console.error('Error:', error.message);
    }
  },

  Logout: async () => {
    // const apiUrl = `${express_server_url}/api/UserInfo/logout`;
    // axios.post(apiUrl,{},{})

    alert("logout")


  }
  ,

  login: async () => {
    try {
      const apiUrl = `${WebApiUrl}/api/UserInfo/login`;
      console.log(apiUrl);
      const response = await axios.post(apiUrl, {
        compCode: "C0002",
        userName: "admin",
        password: "admin",
        comClientRefId: "string",
      }, {
        withCredentials: true,
      });

      console.log("Response:", response.data);

      if (response.data.isSuccess) {
        alert("Login successfull");
      } else {
        alert("Incorrect username or password");
      }
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Authentication failed: Incorrect username or password");
      } else {
        alert("An error occurred during login. Please try again.");
      }
    }
    //AccountGroupApi.GetAccountGroups(setMainDataCallback);
    //StateInfoApi.GetState(setStateInfoCallback);
  },

  checkCredentials: async () => {

    try {
      const apiUrl = `${WebApiUrl}/api/UserInfo/EcommerceCredentials`;
      const response = await axios.get(apiUrl, {});
      console.log("Response:", response.data.isSuccess);
      return response.data.isSuccess;
    } catch (error) {
      console.error("Error:", error.message);
      // Handle the error accordingly
    }
  },


  GetCurrentUser: async () => {

    try {
      const apiUrl = `${WebApiUrl}/api/UserCredential/CurrentUser`;
      const response = await axios.get(apiUrl, {});
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
      // Handle the error accordingly 
    }
  }
  ,
  SendToken: async (token) => {
    try {
      console.log(token)
      const apiUrl = `${WebApiUrl}/api/UserInfo/EcommerceCredentials`;
      const response = await axios.post(
        apiUrl,
        { token },
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.isSuccess);
      return response.data.isSuccess
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  LogInAccount: async (data) => {

    try {
      var WebApiUrl = express_server_url;
      WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

      // const apiUrl = `${WebApiUrl}/api/Account/LogIn`;
      const apiUrl = `${WebApiUrl}/api/Account/LogIn?MobileNo=${data.name_email}&Password=${data.password}`;

      const response = await axios.get(apiUrl, {}, {
        withCredentials: true,
        credentials: 'include',
      });

      console.log("Response:", response.data);
      return response.data;

    } catch (error) {
      console.error("Error:", error.message);
      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  SendEmail: async (data) => {
    try {
      var WebApiUrl = express_server_url;
      WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

      // const apiUrl = `${WebApiUrl}/api/Account/LogIn`;
      const apiUrl = `${WebApiUrl}/api/SendEmail/sendmail`;

      const response = await axios.post(apiUrl, data, {
        withCredentials: true,
        credentials: 'include',
      });

      console.log("Response:", response.data);
      return response.data;

    } catch (error) {
      console.error("Error:", error.message);
      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  }
};

export default User;