import React, { useContext, useEffect, useState, useMemo } from "react";
import coupon from "../../Assets/Icons/discount.png"
import Modal from "../../Component/Modal/Modal";
import AlertBox from "../../Component/AlertBox/AlertBox";
import AddCouponModal from "../../Component/Modal/AddCouponModal";
import Order from "../../../API/API_SERVISES/Order";
import dlt from "../../Assets/Icons/trash.png"
import MyContext from "../../MyContext";
import axios from 'axios';
import swal from 'sweetalert';
import AlertSuccess from "../../Component/AlertBox/AlertSuccess";
import { Link } from "react-router-dom";
import Logo from "./../../Assets/Image_lc/logo.jpg"
import Loader1 from "../../Component/Loaders/Loader1";

const Checkout = (props) => {
    const [modal, setModal] = useState(false);
    const [alert, setAlert] = useState(false);
    const [addcoupon, setAddCoupon] = useState(false);
    const [sweetAlert, setSweetAlert] = useState(false);

    const alertbox = () => {
        setAlert(!alert)
    }

    const OpenAddCoupon = () => {
        setAddCoupon(!addcoupon)
    }

    const { setTotalQuantity, totalQuantity, InputValues, setInputValues, imageBaseUrl, fetchDataWish, currentUser, wishList, setWishList, fetchDataCart, companyData } = useContext(MyContext)


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const signin = () => {
        setModal(!modal)
    }
    const {
        item
    } = props;


    const [EcomCartId, setDeleteCart] = useState(null)
    const [product, setProduct] = useState({
        id: InputValues.id,
        quantity: InputValues.quantity,
    });

    // const fetchData = async () => {
    //     try {
    //         const val = await Order.getGetAllEcomCartDetails();
    //         console.log(val);
    //         setInputValues(val);
    //     } catch (error) {
    //         console.error("Error fetching data:", error);
    //     }
    // };
    // useEffect(() => {
    //     if (currentUser === true) {
    //         fetchData();
    //     }
    // }, [currentUser]);

    // **************Delete******************

    const DeleteStation = (id) => {
        console.log(id);
        DeleteDeleteEcomCartDetail(id);
    }
    const DeleteDeleteEcomCartDetail = async (id) => {
        try {
            const val = await Order.deleteDeleteEcomCartDetail(id);
            setDeleteCart(val)
            fetchDataCart();
        } catch (error) {
            console.error("Error deleting data:", error);
        }
        fetchDataCart();
    };

    const [quantity1, setQuantity] = useState();
    const [loading, setLoading] = useState(false);

    const handleCartIncremrnt = async (d2AccountId, d2ItemId, quantity, linkedItm) => {
        setLoading(true);
        // debugger; console.log(InputValues)
        try {
            // Assuming handleChange is defined elsewhere in your code
            const updatedValues = [
                {
                    operationType: 0,
                    path: "Quantity",
                    op: "Add",
                    from: "string",
                    value: quantity + 1 // Assuming you want to increment the quantity 
                }
            ];

            const isSuccess = await Order.UpdateEcomCartPartially(updatedValues, d2AccountId, d2ItemId);

            // ****************** Update Lens qty with change in frame qty **********************
            const filterData = InputValues.filter(item => item.linkedItm == linkedItm);  //for lens qty update
            if (filterData && filterData.length > 0) {
                const lensItem = filterData.find(item => item.lensType != "" || item.lensType != null || item.lensType != "NA")
                if (lensItem) {
                    const updatedValues = [
                        {
                            operationType: 0,
                            path: "Quantity",
                            op: "Add",
                            from: "string",
                            value: quantity + 1,
                        }
                    ];
                    const isSuccess = await Order.UpdateEcomCartPartially(updatedValues, lensItem.id);
                }
            }

            if (isSuccess) {
                fetchDataCart();
            } else {
                throw new Error("Error while updating cart");
            }
        } catch (error) {
            console.error("Error while updating cart:", error);
            fetchDataCart();

        } finally {
            setLoading(false);
        }
        fetchDataCart();
    };

    const handleCartDecrement = async (d2AccountId, d2ItemId, quantity, linkedItm) => {
        setLoading(true);
        try {
            // Assuming handleChange is defined elsewhere in your code
            const updatedValues = [
                {
                    operationType: 0,
                    path: "Quantity",
                    op: "Add",
                    from: "string",
                    value: quantity - 1 // Assuming you want to increment the quantity
                },
            ];

            const isSuccess = await Order.UpdateEcomCartPartially(updatedValues, d2AccountId, d2ItemId);

            // ****************** Update Lens qty with change in frame qty **********************
            const filterData = InputValues.filter(item => item.linkedItm == linkedItm);  //for lens qty update
            if (filterData && filterData.length > 0) {
                const lensItem = filterData.find(item => item.lensType != "" || item.lensType != null || item.lensType != "NA")
                if (lensItem) {
                    const updatedValues = [
                        {
                            operationType: 0,
                            path: "Quantity",
                            op: "Add",
                            from: "string",
                            value: quantity - 1,
                        }
                    ];
                    const isSuccess = await Order.UpdateEcomCartPartially(updatedValues, lensItem.id);
                }
            }
            if (isSuccess) {
                // alert("Cart Updated successfully");
                fetchDataCart();
            } else {
                throw new Error("Error while updating cart");
            }
        } catch (error) {
            console.error("Error while updating cart:", error);
            // alert("Error while updating cart");
            fetchDataCart();
        } finally {
            setLoading(false);
        }
        fetchDataCart();
    };

    const [totalPrice, setTotalPrice] = useState(0);
    const [taxtotalPrice, setTaxTotalPrice] = useState(0);
    const [finalPrice, setFinalPrice] = useState(0);
    const [productPrice, setProductPrice] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const itemsPerPage = 3; // Number of items to show per page
    const itemsPerPage2 = 1; // Number of items to show per page

    useEffect(() => {
        // Calculate total price
        const calculateTotalPrice = () => {
            let totalPrice = 0;
            let taxPrice = 0;
            let finalPrice = 0;
            let productPrice = 0;
            let productQuantity = 0;
            console.log(InputValues)
            InputValues.forEach(product => {
                totalPrice += product.itemPrice * product.quantity
                productPrice += product.itemPrice
                productQuantity += product.quantity
            });
            console.log(finalPrice);
            setTotalPrice(totalPrice);
            setTaxTotalPrice(taxPrice);
            setFinalPrice(finalPrice);
            setProductPrice(productPrice)
            setTotalQuantity(productQuantity)

        };

        calculateTotalPrice();
    }, [InputValues]);
    console.log(InputValues)

    useEffect(() => {
        if (currentUser === true) {
            fetchDataWish();
        }
    }, [currentUser]);

    const handleNext = () => {
        if (currentIndex + itemsPerPage2 < wishList.length) {
            setCurrentIndex(currentIndex + itemsPerPage2);
        }
    };

    const handlePrev = () => {
        if (currentIndex - itemsPerPage2 >= 0) {
            setCurrentIndex(currentIndex - itemsPerPage2);
        }
    };

    return (
        <>
            <div>

                <div className="cartpage-high-theme3">
                    <div className="cartpage-left-theme3">
                        {/* <div>
                            <div className="arrow-box-theme2">
                                <img src={Logo} alt="" />
                                <i class="fa-solid fa-caret-right"></i>
                            </div>
                        </div> */}
                        <div className="check-out-main-head">
                            <div>LogIn in as 8839593872  <span>LogOut</span></div>
                        </div>
                        <div className="cart-shipping-address-co-1">
                            <div>Shipping Address</div>
                        </div>
                        <div className="cart-add-ads-1">
                            <div className="cart-add-ads-3">SAVED ADDRESS</div>
                            <div><button className="checkout-step-two">Continue</button></div>
                        </div>
                        <div>
                            <div className="chech-addrss-add-4">
                                <i class="fa-solid fa-plus"></i>
                                <span>ADD NEW ADDRESS</span>
                            </div>
                        </div>

                        <div className="cartpage-reviewbox">
                            <div className="cart-container-scroll" >
                                <div>Subham Bala</div>
                                <div>MMI Hospital</div>
                                {InputValues.map((product) => (
                                    <div className="review-box review-box-2-theme3" key={product.id}>
                                        <div className="review-box-image">
                                            <img src={`${imageBaseUrl}${product.itemImgId}`} alt="" />

                                        </div>
                                        <div className="review-box-detail">
                                            <p onClick={() => DeleteStation(product.id)} className="cartpage-2-product-remove">
                                                <i className="fa-regular fa-trash-can"></i>
                                            </p>
                                            <div className="review-box-options--theme3" style={{ paddingTop: "5px" }}>
                                                <div className="heading-panel-1-theme3">{product.itemName}
                                                    Blue Block Phone Computer Glasses:  Blue Sky Sky Blue Transparent Full Rim Wayfarer
                                                    Lens2cart HUSTLR LB E14058-W C3
                                                </div>
                                                <div className="prize-55">
                                                {companyData.currencySymbol} {product.itemPrice}
                                                </div>
                                            </div>
                                            <div className="review-box-options--theme3">
                                                <div>LENS - {product.lensType}</div>
                                                <div className="prize-55">
                                                {companyData.currencySymbol} {product.itemPrice}
                                                </div>
                                            </div>
                                            <div className="review-box-options--theme3">
                                                <div>Final prize</div>
                                                <div className="prize-55">
                                                {companyData.currencySymbol} {product.itemPrice * product.quantity}
                                                </div>
                                            </div>
                                            <p className="review-box-detail-line-4" style={{ marginBottom: "3px", paddingLeft: "5px" }}>
                                                <div className="quantity">
                                                    <a onClick={() => handleCartDecrement(product.d2AccountId, product.d2ItemId, product.quantity, product.linkedItm)} className="quantity__minus">
                                                        <span>-</span>
                                                    </a>
                                                    <input name="quantity" type="text" className="quantity__input" value={product.quantity} />
                                                    <a onClick={() => handleCartIncremrnt(product.d2AccountId, product.d2ItemId, product.quantity, product.linkedItm)} className="quantity__plus">
                                                        <span>+</span>
                                                    </a>
                                                </div>
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="cartpage-right-theme3">
                        <div style={{ marginLeft: "5px" }} className="cartpage-heading-theme3">Price Summary</div>

                        <div className="">
                            <div className="cartpage-pricebox">

                                <div className="pricebox-type-1">
                                    <div className="pricebox-heading">
                                        Item Total
                                    </div>
                                    <div className="pricebox-heading-2">
                                        <i className="fa-solid fa-indian-rupee-sign"></i> {totalPrice}
                                    </div>
                                </div>
                                <div style={{ color: "rgb(17, 186, 216)" }} className="pricebox-type-1">
                                    <div className="pricebox-heading">
                                        Item Coupon
                                    </div>
                                    <div className="pricebox-heading-2">
                                        <i className="fa-solid fa-indian-rupee-sign"></i> 
                                    </div>
                                </div>
                                <div className="pricebox-type-1">
                                    <div className="pricebox-heading">
                                        Net Amount
                                    </div>
                                    <div className="pricebox-heading-2">
                                        <i className="fa-solid fa-indian-rupee-sign"></i> {totalPrice}
                                    </div>
                                </div>
                                <div style={{ border: '0' }} className="pricebox-type-1">
                                    <div className="pricebox-heading">
                                        Total payable (incl. taxes)
                                    </div>
                                    <div className="pricebox-heading-2">
                                        <i className="fa-solid fa-indian-rupee-sign"></i> {totalPrice}
                                    </div>
                                </div>
                            </div>
                            <div style={{ cursor: "pointer" }} onClick={OpenAddCoupon} className="cartpage-pricebox">
                                <div className="pricebox-1">
                                    <div style={{ display: "flex" }}>
                                        <img src={coupon} alt="" />
                                        <div className="pricebox-coupon">Add Coupon Code</div>
                                    </div>
                                    <i className="fa-solid fa-caret-right"></i>
                                </div>
                            </div>
                            <button className="pricebox-type-btn-theme3" onClick={signin}>Proccesed To CheckOut</button>
                        </div>

                    </div>
                    {modal && (
                        <Modal handleClick={signin} />
                    )}
                    {alert && (<AlertBox alertname="Remove Item From Cart" close={() => DeleteDeleteEcomCartDetail()} />)}
                    {addcoupon && (<AddCouponModal handleClick={OpenAddCoupon} />)}
                    {/* <AlertSuccess /> */}
                </div>
                <div className="cartpage-theme4">
                    <div className="wish-side-ihdds">
                        <div>
                            <h2 className="head-body-arrow">Add items from your WishList</h2>
                        </div>
                        <div>
                            <i className="fa-solid fa-arrow-left left-right-arrow" onClick={handlePrev}></i>
                            <i className="fa-solid fa-arrow-right left-right-arrow" onClick={handleNext}></i>
                        </div>
                    </div>
                    <div style={{ cursor: "pointer" }} className="cartpage-pricebox-container-theme3">
                        {wishList.slice(currentIndex, currentIndex + itemsPerPage).map((product, index) => (
                            <div key={index} className="pricebox-wishlist-container">
                                <div style={{ display: "flex" }}>
                                    <img src={`${imageBaseUrl}${product.itemImgId}`} alt="" />
                                </div>
                                <div className="title-name-wl-3">
                                    <p>{product.itemName}</p>
                                </div>
                                <div className="price-show-wl-3">
                                    <i className="fa-solid fa-indian-rupee-sign"></i> {product.itemPrice}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {loading && <Loader1 />}
            </div>
        </>
    );
};

export default Checkout;
