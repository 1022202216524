import React, { useContext, useEffect, useState } from 'react'
import "../../Component/KidsGlasses/Kidsglasses.css"
import sunglasses from "../../Assets/Image_lc/Sunnies.webp"
import Eyeglasses from "../../Assets/Image_lc/glasses.webp"
import Computerglasses from "../../Assets/Image_lc/computer-glasses.webp"
import MyContext from '../../MyContext'
import { Link } from 'react-router-dom';

const Kidsglasses = ({ CategoryData, parentId }) => {
    const { companyData, imageBaseUrl } = useContext(MyContext);
    console.log(CategoryData);
    console.log(parentId);
    console.log(imageBaseUrl);




    // const [filteredCategories, setFilteredCategories] = useState([]);
    // console.log(filteredCategories);


    // useEffect(() => {

    //     const filteredData = CategoryData.filter(item => item.parentId === parentId);
    //     setFilteredCategories(filteredData);
    // }, [CategoryData, parentId]);


    // const data = [
    //     {
    //         name: "Eyeglasses",
    //         imgsrc: Eyeglasses
    //     },

    //     {
    //         name: "Zero power screen glasses",
    //         imgsrc: Computerglasses
    //     },

    //     {
    //         name: "Sunglasses",
    //         imgsrc: sunglasses
    //     }
    // ]

    return (
        <div className={`kidglasses-main`}>
            {/* <div className={`kidglasses-subdiv`}>
                {
                    filteredCategories.map((item, index) => {
                        return (
                            <>
                                <div className={`single-div-for-man`}>
                                    <img className={`img-single-div`} src={`${imageBaseUrl}/${item.imageBaseUrl}`} />
                                    <p className={`p-img-single-div`}>
                                        {item.name}
                                    </p>
                                </div>
                            </>
                        )
                    })
                }
            </div> */}

            <div className="kidglasses-subdiv">
                {
                    CategoryData.map((item, index) => {
                        if (item.primaryGroup === false && item.showInMenu === true && item.parentGrp === parentId) {
                            return (
                                 <Link to={`/productsearch?productId=${item.d2_Id}`} className="">
                                <div className="single-div-for-man" key={index}>
                                    <img className="img-single-div" src={`${imageBaseUrl}${item.images[0]}`} alt="" />
                                    <p className="p-img-single-div">{item.menuName}</p>
                                </div>
                                </Link>
                            );
                        }
                        return null; 
                    })
                }
            </div>
        </div>
    )
}

export default Kidsglasses