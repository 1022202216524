import axios from "../axiosConfig"; // Adjust the import path accordingly

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const LoyaltyMaster = {
  CreateLoyaltyMemberShipAccountCardDetails: async (data) => {
    // const data = {};
    try {
      const apiUrl = `${WebApiUrl}/api/LoyaltyMaster/CreateLoyaltyMemberShipAccountCardDetails`;
      const response = await axios.post(apiUrl, data, {
        withCredentials: true,
      });
      console.log("Response:", response.data.result);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  GetAllLoyaltyMemberShipAccountCardDetails: async () => {
    try {
      const apiUrl = `${WebApiUrl}/api/LoyaltyMaster/GetAllLoyaltyMemberShipAccountCardDetails`;
      const response = await axios.post(
        apiUrl,
        { filter: [], order: [], attribute: [], pageNumber: 0, pageSize: 5000 },
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  // ************************* Get By Id ****************************
  GetLoyaltyMemberShipAccountCardDetail: async (CardId) => {
    try {
      const apiUrl = `${WebApiUrl}/api/LoyaltyMaster/GetLoyaltyMemberShipAccountCardDetail/${CardId}`;
      const response = await axios.get(apiUrl, {
        withCredentials: true,
      });
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },
  // ************************* Update By Id ****************************
  UpdateLoyaltyMemberShipAccountCardDetail: async (data) => {
    try {
      debugger;
      const apiUrl = `${WebApiUrl}/api/LoyaltyMaster/UpdateLoyaltyMemberShipAccountCardDetail`;
      const response = await axios.put(
        apiUrl,
        { data },
        { withCredentials: true, credentials: "include" }
      );
      console.log("Response:", response.data.result);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);
      // You might want to throw the error or handle it based on your application's requirements
      throw error;
    }
  },

  // ************************* Delete ****************************
  DeleteLoyaltyMemberShipAccountCardDetail: async (CardId) => {
    try {
      const apiUrl = `${WebApiUrl}/api/LoyaltyMaster/DeleteLoyaltyMemberShipAccountCardDetail?CardId=${CardId}`;
      const response = await axios.delete(apiUrl, {
        withCredentials: true,
      });
      console.log("Delete Response:", response.data.result);
      return response.data.isSuccess;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////                         Loyalty MemberShip Account Start                                   ///////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  CreateLoyaltyMemberShipAccount: async (data) => {
    try {
      const apiUrl = `${WebApiUrl}/api/LoyaltyMaster/CreateLoyaltyMemberShipAccount`;
      const response = await axios.post(apiUrl, data, {
        withCredentials: true,
      });
      console.log("Response:", response.data.result);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  GetAllLoyaltyMemberShipAccount: async (
    filterData = {
      filter: [],
      order: [],
      attribute: [],
      pageNumber: 0,
      pageSize: 5000,
    }
  ) => {
    try {
      const apiUrl = `${WebApiUrl}/api/LoyaltyMaster/GetAllLoyaltyMemberShipAccount`;
      const response = await axios.post(apiUrl, filterData, {
        withCredentials: true,
      });
      console.log("Response:", response.data.result);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },
  // ************************* Get By Id ****************************
  GetLoyaltyMemberShipAccount: async (MemberId) => {
    try {
      const apiUrl = `${WebApiUrl}/api/LoyaltyMaster/GetLoyaltyMemberShipAccount/${MemberId}`;
      const response = await axios.get(apiUrl, {
        withCredentials: true,
      });
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },
  // ************************* Update By Id ****************************
  UpdateLoyaltyMemberShipAccount: async (data) => {
    try {
      debugger;
      const apiUrl = `${WebApiUrl}/api/LoyaltyMaster/UpdateLoyaltyMemberShipAccount`;
      const response = await axios.put(
        apiUrl,
        { data },
        { withCredentials: true, credentials: "include" }
      );
      console.log("Response:", response.data.result);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);
      // You might want to throw the error or handle it based on your application's requirements
      throw error;
    }
  },

  // ************************* Delete ****************************
  DeleteMemberShipAccMaster: async (MemberAccId) => {
    try {
      const apiUrl = `${WebApiUrl}/api/LoyaltyMaster/DeleteMemberShipAccMaster?MemberAccId=${MemberAccId}`;
      const response = await axios.delete(apiUrl, {
        withCredentials: true,
      });
      console.log("Delete Response:", response.data.result);
      return response.data.isSuccess;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////                         Loyalty MemberShip Account End                                     ///////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
};

export default LoyaltyMaster;
