import React from 'react'

const CustomerReview = () => {
    return (
        <div>
            <div style={{ background: "#fff092" }}>
                <div className='customer-title-1'>
                    <h3 className='customer-main-title'>VIDEOS</h3>
                    <div className='link-youtube-frame'>
                        <div className="customer-link-detail">
                            <iframe className="frame-yt-video " src="https://www.youtube.com/embed/7nUyehZ0xvM?si=eQVyCftbwc1CXsJU" frameborder="0" allowfullscreen></iframe>
                        </div>
                        <div className="customer-link-detail">
                            <iframe className="frame-yt-video " src="https://www.youtube.com/embed/ExDCXACJzjw?si=NpY_AMh-jzji82LR" frameborder="0" allowfullscreen></iframe>
                        </div>
                        <div className="customer-link-detail">
                            <iframe className="frame-yt-video " src="https://www.youtube.com/embed/cjnxSk_Cw10?si=WX192so19ETndA98" frameborder="0" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomerReview