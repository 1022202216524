import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; // Import Swiper styles
import { Navigation, Pagination, Autoplay } from 'swiper/modules'; // Import Autoplay module
import 'swiper/css/navigation';
import 'swiper/css/pagination'; // Import pagination styles

const Testimonial = () => {
    return (
        <section className="Testimonialcontainer">
            <Swiper
                slidesPerView={1}
                grabCursor={true}
                loop={true}
                pagination={true} // Enable pagination
                navigation={true} // Enable navigation
                modules={[Pagination, Navigation, Autoplay]} // Register modules
                autoplay={{ // Configure autoplay
                    delay: 3000, // 3 seconds delay
                    disableOnInteraction: false, // Autoplay continues after user interactions
                }}
                className="testimonial mySwiper"
            >
                <SwiperSlide className="slide">
                    <img src="https://i.ibb.co/3m5x6B5/people6.jpg" alt="" className="image" />
                    <p>
                    To recreate my fashion mantra, I had ordered a pair of quirky round eyeglasses from the John Jacobs Slim collection at Lens2cart and boy,
                    was I mesmerized by the new pair. With seamless delivery and without any payment hassle,
                    I received my frame within 2 days of placing the order. Each one of my family members, 
                    friends and colleagues complimented me on my new quirky look achieved by wearing the stylish glasses.
                    I would rate the Lens2cart service with 5 stars. Extremely happy with the service.
                    </p>
                    <i className="bx bxs-quote-alt-left quote-icon"></i>
                    <div className="details">
                        <span className="name">Anjana Shekhawat</span>
                        <span className="job">Kenya</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="slide">
                    <img src="https://i.ibb.co/hFVbbty/people3.jpg" alt="" className="image" />
                    <p>
                    Ordered a pair of Vincent Chase Revo sunglasses and absolutely loved them. 
                    They make me look like a fashionista every time I step out wearing them.
                     All of my friends so loved them that they also ordered the very same pair from Lens2cart. 
                     Jazzy, chic and quite the crowd-puller, they are perfect for me. Love you Lens2cart!
                    </p>
                    <i className="bx bxs-quote-alt-left quote-icon"></i>
                    <div className="details">
                        <span className="name">Nikita Malhotra</span>
                        <span className="job">Kenya</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="slide">
                    <img src="https://i.ibb.co/Lh9gfFZ/people2.jpg" alt="" className="image" />
                    <p className='test-monial'>
                    Hi! First of all, thank you Lens2cart for the amazing variety, discounts and timely delivery. 
                    I was going through the net struggling to find a present for my father, 
                    when I purchased the Vincent Chase Round Black eyeglasses. 
                    He had a similar frame long ago which he loved and couldn’t find a piece like that anywhere. 
                    Vincent Chase frames made it a perfect gift for him this Father’s Day.
                    </p>
                    <i className="bx bxs-quote-alt-left quote-icon"></i>
                    <div className="details">
                        <span className="name">Jayant Das</span>
                        <span className="job">Kenya</span>
                    </div>
                </SwiperSlide>
            </Swiper>
        </section>
    );
}

export default Testimonial;
