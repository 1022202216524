import React, { useContext, useEffect, useRef, useState } from 'react'
import "./ContactPage.scss"
import { Icon } from '@material-ui/core'
// import Swal from 'sweetalert2';
import User from '../../../API/API_SERVISES/User';
import MyContext from '../../MyContext';
import { useLocation } from 'react-router-dom';

const ContactPage = () => {
    const { companyData, imageBaseUrl, shopBanner } = useContext(MyContext);
    console.log(companyData);

    const [crosstogle, setcrosstogle] = useState(false)
    const [email, setemail] = useState(null)
    const [name, setname] = useState(null)
    const [bodytext, setbodytext] = useState(null)
    const form = useRef();

    useEffect(() => {
        setTimeout(() => {
            setcrosstogle(true)
        }, 4000);
    }, []);

        const useQuery = () => {
            return new URLSearchParams(useLocation().search);
        }
    
        const location = useLocation();
        const query = useQuery();
        const productId = query.get('productId');



    const ImageId = shopBanner.filter(item => item.showInCategoryGroup == productId).map(image => image.bannerImageId)
    console.log(ImageId);


    // ----------------------SEND EMAIL --------------------
    const sendEmail = async (e) => {
        e.preventDefault()
        debugger
        if (!name || !email || !bodytext) {
            return alert(`Please fill all details!`)
        }

        try {
            // emailjs
            //     .sendForm('service_6nblfv9', 'template_2vrz7ln', form.current, {
            //         publicKey: 'hSvu5oZJ0Dl4mlZON',
            //     })
            //     .then(
            //         () => {
            //             console.log('SUCCESS!');
            //             Swal.fire({
            //                 icon: 'success',
            //                 title: 'Email Sent Successful!',
            //                 text: 'Your message was sent successfully',
            //                 confirmButtonText: 'Okay',
            //             });
            //         },
            //         (error) => {
            //             console.log('FAILED...', error.text);
            //             Swal.error({
            //                 icon: 'error',
            //                 title: 'Email Sent Failed!',
            //                 confirmButtonText: 'Okay',
            //             });
            //         },
            //     );

            const data = {
                text: `
                Username: ${name}
                Email: ${email}
                message: ${bodytext}
                `,
                subject: `Message from lens2kart contact!`,
                to: "help.lens2cart@gmail.com"
            }

            await User.SendEmail(data).then(() => {
                alert(`Message sent successfully!`)
            }).catch(err => {
                alert(err.message)
            })
        } catch (error) {
            console.log(error)
        }
    };


    const handleEmailChange = (event) => {
        setemail(event.target.value);
    };


    const handleSubscribe = async (e) => {
        e.preventDefault();
        
        if (!email) {
            return alert("Please enter your email!");
        }

        try {
            const data = {
                text: `Email : ${email}`,
                subject: `New Subscription to Lens2Cart Newsletter`,
                to: "help.lens2cart@gmail.com", 
            };

            await User.SendEmail(data).then(() => {
                alert(`Message sent successfully!`)
            }).catch(err => {
                alert(err.message)
            })
            setcrosstogle(false);
        } catch (error) {
            console.log(error)
        }
    };


    return (
        <>
            <div className={`contact-page`}>
                {/* -------topimage---------- */}
                <div className='contact-relative-page'>
                    {ImageId.length > 0 && (
                        <img 
                            style={{
                                height: ImageId.length === 0 ? "0" : ""
                            }}
                            className="contact-page-banner"
                            src={ImageId.length > 0 ? `${imageBaseUrl}${ImageId[0]}` : ""}
                            alt=""
                        />
                    )
                    }
                    <div className={`contact-detail`}>
                        <h1>CONTACT</h1>
                        <div></div>
                        <p>IF YOU HAVE A PROJECT YOU WOULD LIKE TO DISCUSS, GET IN TOUCH WITH US.</p>
                    </div>
                </div>

                {/* ----------middle-------------- */}
                <div className={`contactpage-middle`}>
                    {/* ------left--------- */}
                    <form ref={form} onSubmit={(e) => sendEmail(e)} className={`contact-middle-left`}>
                        <div className={`contact-middle-box`}>
                            <label>Your Lovely Name *</label>
                            <input onChange={(e) => setname(e.target.value)} type='text' name='user_name' />
                        </div>
                        <div className={`contact-middle-box`}>
                            <label>Email *</label>
                            <input onChange={(e) => setemail(e.target.value)} type='text' name='user_email' />
                        </div>
                        <div className={`contact-middle-box`}>
                            <label>Tell Us Everything:</label>
                            <textarea onChange={(e) => setbodytext(e.target.value)} className={`tell-us-everything`} type='text' name='message' />
                        </div>


                        {/* ----------submit------- */}
                        <div className={`contact-middle-submit`}>
                            <button type='submit'>
                                SEND
                            </button>
                        </div>
                    </form>

                    {/* ----------right----------- */}
                    <div className={`contact-midle-right`}>
                        <h2>CONTACT INFO</h2>
                        <div className={`contact-all-info`}>
                            <div className={`contact-info-single`}>
                                <div>
                                    <i class="fa fa-map-marker" aria-hidden="true"></i>
                                </div>

                                <p> {companyData.firmAddress1}</p>
                            </div>
                            {/* <div className={`contact-info-single`}>
                                <div>
                                    <i class="fa-solid fa-phone"></i>
                                </div>
                                <p>{companyData.mobileNo}
                                    Call / Whatsapp/ Telegram</p>
                            </div> */}
                            <div className={`contact-info-single`}>
                                <div>
                                    <i class="fa-solid fa-envelope"></i>
                                </div>
                                <p> {companyData.email}
                                </p>
                            </div>
                        </div>


                    </div>


                </div>
                {/* ----------map----------------- */}
                <div className={`contact-info-map`}>
                    <iframe style={{ border: "none" }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d530.4185689890259!2d36.82102424959869!3d-1.261360800000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f171bd8368527%3A0xef1bf33a94c0c87!2sPrime%20Bank!5e1!3m2!1sen!2sin!4v1733835044422!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                </div>

            </div>

            {/* ------------popup---------- */}

            {
                crosstogle && (
                    <div className={`${crosstogle ? "contact-pop-up" : "contact-pop-close"}`}>
                        <div className={`contact-pop-box`}>
                            {/* ------top------- */}
                            <div className={`contact-pop-top`}>
                                <h2>Subscribe for exclusive Lens2Cart newsletter</h2>
                                <p>To receive early bird promotional offers and new deals updates</p>
                            </div>

                            {/* -------------bottominput--------- */}
                            <div className={`bottom-input`}>
                                <input type='email' name='text' 
                                value={email}
                                onChange={handleEmailChange}
                                placeholder='Your Email' />
                                <button className={`subscribe-btn`} onClick={handleSubscribe}>
                                    Subscribe
                                </button>
                            </div>

                            {/* -----cross-btn------------ */}
                            <button onClick={() => setcrosstogle(false)} className={`contact-cross-btn`}>
                                x
                            </button>
                        </div>
                    </div>
                )
            }


        </>
    )
}

export default ContactPage