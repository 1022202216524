import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import MyContext from "../../../MyApp/MyContext";
import Order from "../../../API/API_SERVISES/Order";
import ItemMaster from "../../../API/API_SERVISES/ItemMaster";
import useIntersectionObserver from "../useIntersectionObserver";

const ProductCart1 = (props) => {
  const { items, itemList, isVariationItmShowInCatPage } = props
  const [item, setitem] = useState(items);
  const [isHovered, setHovered] = useState(false);
  const { imageBaseUrl, fetchDataCart, wishList, changegrid, ProducrDetailPageURL } = useContext(MyContext);
  const [isImageLoaded, setIsImageLoaded] = useState(true); // Default to true for the initial image.
  const [Colors, setColors] = useState([]);
  const [variations, setvariations] = useState([]);
  const [imageSrc, setImageSrc] = useState('');

  const loadImage = async (element) => {
    const fetchVariations1 = async (d1Id) => {
      if (isVariationItmShowInCatPage == false && variations?.length == 0) {
        if (item.attributeParentId != 0) {
          try {
            var data = await ItemMaster.EcomItemsVariation(d1Id);
            console.log(data);
            setvariations(data);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        }
      }
    }


    if (variations?.length == 0) {
      await fetchVariations1(item.d1Id)
    }
    if (item && item.images && item.images.length > 0) {
      setImageSrc(`${imageBaseUrl}`);
    }
  };

  useEffect(() => {
    if (variations?.length > 0) {
      var color = variations
        .flatMap(item =>
          item.variation
            .filter(variationItem => variationItem.attr_prnt_nm.toLowerCase() === "color" || variationItem.attr_prnt_nm.toLowerCase() === "colour") // Filter for Colour
            .map(variationItem => variationItem.attr_nm) // Extract attr_nm
        );
      var uniqueColors = [...new Set(color)];
      setColors(uniqueColors)
    }
  }, [variations])


  const imageRef = useIntersectionObserver(loadImage, { threshold: 0.1 });

  const [hoveredImage, setHoveredImage] = useState(item?.images[0]);


  useEffect(() => {
    // setHoveredImage(item.images[0])
    if (isImageLoaded && imageRef.current) {
      imageRef.current.style.backgroundImage = `url(${imageSrc}${hoveredImage})`;
    }
  }, [hoveredImage, isImageLoaded, imageSrc]);

  const [, setInputValues] = useState({
    d1ItemId: item.d1ItemId,
    d2ItemId: item.d2ItemId,
    d1AccountId: 0,
    d2AccountId: 0,
    userId: item.userId,

  });

  const toggleWishlist = async (itemData) => {

    console.log(itemData);
    console.log(item);
    console.log(wishList);
    const existingIndex = wishList.find(product => product.d1ItemId === item.d1Id);
    console.log(existingIndex);
    if (existingIndex !== undefined) {
      // alert("Item is already in wishlist");
      try {
        const val = await Order.deleteDeleteFavouriteItemList(existingIndex.id);
        if (Boolean(val) === true) {
          // alert("Delete data successfully", "Ok");
          // fetchData()
        } else {
          alert("Error Deleting Data");
        }
        // fetchData();
      } catch (error) {
        console.error("Error deleting account:", error);
      }
      return;
    } else {

      const data = {
        d1ItemId: item.d1Id,
        d2ItemId: item.d2Id,
        d1AccountId: 0,
        d2AccountId: 0,
        userId: 1,
      };
      try {
        const isSuccess = await Order.insertCreateFavouriteItemList(data);
        if (isSuccess) {
          // alert("Added to wishlist successfully");
          // fetchData()
        } else {
          throw new Error("Error while adding to wishlist");
        }
      } catch (error) {
        console.error("Error while adding to wishlist:", error);
        alert("Error while adding to wishlist");
      }
      setInputValues({
        d1ItemId: "",
        d2ItemId: "",
        d1AccountId: "",
        d2AccountId: "",
        userId: "",
      });
    };
  }


  const AddToCart = async () => {
    // debugger
    var loginDataString = localStorage.getItem("accountLogin");
    var loginData = JSON.parse(loginDataString);
    if (loginData === null) {
      loginData = {
        d1Id: 0,
        d2Id: 0,
      }
    }
    console.log(item)
    const data = {
      d1ItemId: item.d1Id || item.d1ItemId,
      d2ItemId: item.d2Id || item.d2ItemId,
      d1AccountId: loginData ? (loginData.d1Id || 0) : 0,
      d2AccountId: loginData ? (loginData.d2Id || 0) : 0,
      userId: 1,
      quantity: 1,
      unit: "Pcs",
      clientId: 0,
      itemPrice: item.salePrice,
      lensPrice: item.lensPrice,
      lensName: item.lensName,
      coatingPrice: item.coatingPrice,
      coatingName: item.coatingName,

      // box_R: inputValues.boxesRight,
      // box_L: inputValues.boxesLeft,
      // sph_R: inputValues.sphRight,
      // sph_L: inputValues.sphLeft,
      // cyl_R: inputValues.cylRight,
      // cyl_L: inputValues.cylLeft,
      // axis_R: inputValues.axisRight,
      // axis_L: inputValues.axisLeft,
      // add_R: inputValues.addRight,
      // add_L: inputValues.addLeft,
      // dia_R: inputValues.diaRight,
      // dia_L: inputValues.diaLeft,
      // bC_R: inputValues.bcRight,
      // bC_L: inputValues.bcLeft,
    }
    const isSuccess = await Order.insertCreateEcomCartDetail(data);
    if (isSuccess) {
      // setTotalQuantity(isSuccess.length)
      fetchDataCart()
      setAlertSuccess(true)
      setInputValues({
        d1ItemId: " ",
        d2ItemId: " ",
        d1AccountId: " ",
        d2AccountId: " ",
        userId: " ",
        quantity: " ",
        unit: " ",
        itemPrice: " ",
        lensPrice: "",
        lensName: "",
        coatingPrice: "",
        coatingName: "",
        boxesLeft: "",
        boxesRight: "",
        sph_R: "",
        sph_L: "",
        cyl_R: "",
        cyl_L: "",
        axis_R: "",
        axis_L: "",
        add_R: "",
        add_L: "",
        dia_R: "",
        dia_L: "",
        bcRight: "",
        bcLeft: "",
      });

    }
  }
  const [alertSuccess, setAlertSuccess] = useState(false);
  useEffect(() => {
    if (alertSuccess) {
      const timeoutId = setTimeout(() => {
        setAlertSuccess(false);
      }, 500); // 3 seconds
      return () => clearTimeout(timeoutId);
    }
  }, [alertSuccess]);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const [colorChangefunc, setColorChangeFunc] = useState("color-1");

  const colorchange1 = (event) => {
    setColorChangeFunc(event.target.id);
  };

  return (
    <div className="card-product-main-container11" style={{ width: changegrid == "Grid" && '49vw' }}>
      <div style={{ cursor: "pointer", position: "absolute", right: "0px", zIndex: "1" }} onClick={() => toggleWishlist(item)}>
        {(wishList && wishList.find(product => product.d1ItemId === item.d1Id)) ? (
          <i style={{ right: changegrid === "Grid" ? '10px' : '25px', }} className="fa-solid fa-heart HeartSaveBtn2"></i>
        ) : (
          <i style={{ right: changegrid === "Grid" ? '10px' : '25px', }} className="fa-regular fa-heart HeartSaveBtn2"></i>
        )}
      </div>

      {isVariationItmShowInCatPage == false ? (
        <div className="Card-product-type-1-color-box" style={{ display: changegrid === "List" || changegrid === "Grid" ? 'none' : '' }}>
          {Colors.length >= 2 && Colors.map((color, index) => (
            <div
              key={index} // Add a unique key for each element
              onClick={() => colorchange1(color)} // Pass the color as a prop
              className="outline-coloroption-c2"
            >
              <div
                className="color-option-box-main"
                style={{ backgroundColor: color }} // Correct style syntax for background color
              >
              </div>
            </div>
          ))}
        </div>
      ) : ("")}

      <Link to={`/${ProducrDetailPageURL}/${item.d2Id}`} state={{ item }} className="Card-product-type-1">



        <div
          className="Card-product-type-1-img"
          ref={imageRef}
          style={{
            backgroundImage: `url(${imageSrc}${hoveredImage})`,
            // transition: 'background-image 0.2s ease-in-out', // Optional: Smooth transition effect
          }}
        />

        <div className="Card-product-type-1-bottom">
          <div className="Card-product-type-1-desc-box">
            <div>
              {/* <p className="card-product-name">Black shade glasses</p> */}
              <p className="card-product-name" style={{ margin: "0" }}>
                {item.name}
              </p>

            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className="card-product-price">
                <i className="fa-solid fa-indian-rupee-sign"></i> {item.salePrice}
              </p>
              <p className="card-product-price-2">
                <i className="fa-solid fa-indian-rupee-sign"></i> {item.salePrice * 3}
              </p>
            </div>
            <div>
              <p className="card-product-desc">{item.printName ? item.printName : "No short description found!"}</p>
            </div>
          </div>
        </div>
        <div className="Card-product-type-1-middle">
          <div className="Card-product-type-1-rating-color-box">
            <div className="Card-product-type-1-rating-box">
              <div className="card-product-name" style={{ margin: "0" }}>
                4.7 <i className="fa-solid fa-star cart-star-c2"></i> {item.mrpPrice}
              </div>
            </div>

          </div>
        </div>
        <div className="Card-product-type-1-end">
          Get 40% OFF in Coupon : LENSFREE
        </div>


      </Link>

    </div>
  );
};

export default ProductCart1;
