import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
import coupon from "../../Assets/Icons/discount.png"
import Modal from "../../Component/Modal/Modal";
import AlertBox from "../../Component/AlertBox/AlertBox";
import AddCouponModal from "../../Component/Modal/AddCouponModal";
import Order from "../../../API/API_SERVISES/Order";
import dlt from "../../Assets/Icons/trash.png"
import MyContext from "../../MyContext";
import axios from 'axios';
import swal from 'sweetalert';
import AlertSuccess from "../../Component/AlertBox/AlertSuccess";
import { Link, useNavigate } from "react-router-dom";
import StateInfoApi from "../../../API/API_SERVISES/StateInfo";
import CountryApi from "../../../API/API_SERVISES/Country";
import AddAddress from "../../../API/API_SERVISES/AddAddress";
import Googlepay from "../../Assets/Images/GooglePay.webp"
import PhonePe from "../../Assets/Images/PhonePe.webp";
import PayStack from ".././../../API/API_SERVISES/PayStack"
import img11 from "./../../Assets/Image_lc/LensImg/single_vision.webp"
import img22 from "./../../Assets/Image_lc/LensImg/bifocal.webp"
import img33 from "./../../Assets/Image_lc/LensImg/zero_power.webp"
import img44 from "./../../Assets/Image_lc/LensImg/tinted_sv.webp"
import ThemeModal from "../ProductDetailPage/ThemeModal";
import Transaction from "../../../API/API_SERVISES/Transaction";
import BillSeriesMaster from "../../../API/API_SERVISES/BillSeriesMaster";
import BillType from "../../../API/API_SERVISES/BillType";

const PaymentMethods = (props) => {
    // const [modal, setModal] = useState(false);
    // const [alert, setAlert] = useState(false);
    const { companyData } = useContext(MyContext);
    const [addcoupon, setAddCoupon] = useState(false);
    // const [sweetAlert, setSweetAlert] = useState(false);
    // const alertbox = () => {
    //     setAlert(!alert)
    // }
    const navigationBar = [
        {
            name: 'Login/Signup',
            disable: true,
            navigationroute: "/",
            // status:
        },
        {
            name: 'Shipping Address',
            disable: false,
            navigationroute: "/ShippingAddress"
        },
        {
            name: 'Payment',
            disable: false,
            navigationroute: "/PaymentMethods"
        },
        {
            name: 'Summary',
            disable: true,
            navigationroute: "/"
        }
    ]
    const navigate = useNavigate()
    const [EditData, setEditData] = useState();
    const [addresses, setAddAddresses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [isSliderOpen, setSliderOpen] = useState(false);
    const [alertSuccess, setAlertSuccess] = useState(false);

    const { fetchDataCart } = useContext(MyContext);

    const handleCheckboxChange = (addressIndex) => {
        setSelectedAddress(addressIndex);
    };

    const OpenAddCoupon = () => {
        setAddCoupon(!addcoupon)
    }
    const { myaccountmodal, setTotalQuantity, modal, setLoginPanel, totalQuantity, InputValues, imageBaseUrl,
        fetchDataWish, currentUser, wishList, setWishList, totalPrice, setTotalPrice } = useContext(MyContext)

    //****************************************************************** */
    const [isModalOpenAddAddress, setIsModalOpenAddAddress] = useState(false);
    const [showSelectAddress, setShowSelectAddress] = useState(true);

    const handleOpenModalAddAddress = () => {
        setIsModalOpenAddAddress(true);
        setShowSelectAddress(false);
        setEditData("")
    };

    const handleGoBack = () => {
        setIsModalOpenAddAddress(false);
        setShowSelectAddress(true); // Show Select Address section when going back
    };

    //******************************Apply Coupon********************************* */

    const [selectedRadio, setSelectedRadio] = useState("table1");
    const handleRadioChange = (event) => {
        setSelectedRadio(event.target.value);
        // const value = event.target.value;
        // setSelectedRadio(value);
        // navigateToPage(value);
        // setSingleLensModalBox(true)
    };

    const toggleSlider = () => {
        // console.log(editData);
        setSliderOpen(true);
    };
    const toggleSliderClose = () => {
        // console.log(editData);
        setSliderOpen(false);
    };

    const [SingleLensModalBox, setSingleLensModalBox] = useState(false)
    const showModal1 = () => {
        setSingleLensModalBox(true)
    }
    const showModal1Hide = () => {
        setSingleLensModalBox(false)
        setShowFirstComponent(true);
    }

    const [showFirstComponent, setShowFirstComponent] = useState(true);
    const [transition, setTransition] = useState(false);

    const handleClick = () => {
        setTransition(true);
        setTimeout(() => {
            setShowFirstComponent(false);
            setTransition(false);
        }, 500);
    };



    //************************************************************ */

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const signin = () => {
        setLoginPanel(!modal)
    }
    const {
        item
    } = props;


    const [activeLink, setActiveLink] = useState("/");
    const handleLinkClick = (link) => {
        setActiveLink(link);
    };



    // const [totalPrice, setTotalPrice] = useState(0);
    // const [taxtotalPrice, setTaxTotalPrice] = useState(0);
    // const [finalPrice, setFinalPrice] = useState(0);
    // const [productPrice, setProductPrice] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const itemsPerPage = 3; // Number of items to show per page
    const itemsPerPage2 = 1; // Number of items to show per page



    //*****************************ADD Address************************************* */
    const [myAccountID, setMyAccountID] = useState();

    useEffect(() => {
        var loginDataString = localStorage.getItem("accountLogin");
        var loginData = JSON.parse(loginDataString);
        // console.log(loginData.d1Id);
        setMyAccountID(loginData.d1Id);
    }, [myAccountID]);


    const [inputValues, setInputValues] = useState({
        accountId: "",
        name: "",
        mobile: "",
        email: "",
        pincode: "",
        state: "",
        country: "",
        address: "",
        locality: "",
        city: "",
        typeOfAddress: 0,
        isDefault: "",
        isActive: true,
    });


    useEffect(() => {
        console.log(EditData);
        const fetchData = () => {
            if (EditData) {
                setInputValues({
                    id: EditData.id,
                    accountId: 707,
                    name: EditData.name,
                    email: EditData.email,
                    mobile: EditData.mobile,
                    pincode: EditData.pincode,
                    state: EditData.state,
                    address: EditData.address,
                    locality: EditData.locality,
                    city: EditData.city,
                    typeOfAddress: EditData.typeOfAddress,
                    isDefault: EditData.isDefault,
                    isActive: true,
                });
            } else {
                setInputValues({
                    accountId: "",
                    name: "",
                    mobile: "",
                    pincode: "",
                    state: "",
                    address: "",
                    locality: "",
                    city: "",
                    typeOfAddress: 0,
                    isDefault: "",
                    isActive: true,
                });
            }
        };

        fetchData();
    }, [EditData]);



    const [errorMessages, setErrorMessages] = useState({
        name: "",
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };


    const handleInputChange1 = (value) => {
        //// debugger
        setInputValues({
            ...inputValues,
            typeOfAddress: Number(value),
        });
    };

    const insertPayment = async (totalAmt) => {
        var dataRes = {
            "status": true,
            "message": "Authorization URL created",
            "data": {
                "authorization_url": "https://checkout.paystack.com/3ni8kdavz62431k",
                "access_code": "3ni8kdavz62431k",
                "reference": "re4lyvq3s3"
            }
        }

        var data = {
            email: "customer@email.com",
            amount: totalAmt, //"20000",
            currency: "KES",
            callback_url: "https://lens2cart.com/PaymentResponse"
        }
        var response = await PayStack.CreatePayment(data)
        if (response.status === 200) {
            const url = response.data.data.authorization_url;
            localStorage.setItem("PayStackPayment", JSON.stringify(response.data.data.reference));
            window.location.href = url;
        }

    }

    // const [stateInfo, setStateInfo] = useState();
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const data = await StateInfoApi.GetAllState();
    //             setStateInfo(data);
    //         } catch (error) {
    //             console.error("Error fetching data:", error);
    //         }
    //     };
    //     fetchData();
    // }, []);

    // const [country, setCountry] = useState();
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const data = await CountryApi.GetAllCountry();
    //             setCountry(data);
    //         } catch (error) {
    //             console.error("Error fetching data:", error);
    //         }
    //     };
    //     fetchData();
    // }, []);


    // const validateInput = (fieldName) => {
    //     // console.log(fieldName);
    //     const fieldValue = inputValues[fieldName];

    //     if (
    //         !fieldValue ||
    //         (typeof fieldValue === "string" && fieldValue.trim() === "")
    //     ) {
    //         setErrorMessages((prevErrors) => ({
    //             ...prevErrors,
    //             [fieldName]: `${toFriendlyName(fieldName)} field is required`,
    //         }));
    //         return false;
    //     }
    // };
    // const fieldNames = [];

    // const toFriendlyName = (fieldName) => {
    //     return fieldName
    //         .replace(/([A-Z])/g, " $1")
    //         .replace(/^./, (str) => str.toUpperCase());
    // };


    // const inputRef1 = useRef(null);
    // const inputRef2 = useRef(null);
    // const inputRef3 = useRef(null);
    // const inputRef4 = useRef(null);

    // const focusInput = (inputRef) => {
    //     inputRef.current.focus();
    // };

    // const SaveBtn = async () => {
    //     // console.log(props.data);
    //     let allValid = true;

    //     fieldNames.forEach((fieldName) => {
    //         const isValid = validateInput(fieldName);

    //         if (!isValid) {
    //             allValid = false;
    //         }
    //     });

    //     if (allValid) {
    //         if (EditData) {
    //             if (inputValues.name === "") {
    //                 alert("Name field is Required")
    //                 focusInput(inputRef1)
    //             }
    //             else if (inputValues.address === "") {
    //                 alert("Address field is Required ")
    //                 focusInput(inputRef2)
    //             }
    //             else if (inputValues.state === "" && inputValues.state === 0) {
    //                 alert("Please select the state ")
    //                 focusInput(inputRef3)
    //             }
    //             else if (inputValues.city === "") {
    //                 alert("City/District feild is required ")
    //                 focusInput(inputRef4)
    //             }
    //             else {
    //                 const address = await AddAddress.updateAddress(
    //                     inputValues,
    //                     myAccountID
    //                 );
    //                 if (address) {
    //                     alert("Address Updated successfully");
    //                     // setEditData();

    //                 } else {
    //                     alert("error while updating");
    //                 }
    //             }
    //         } else {
    //             try {
    //                 if (inputValues.name === "") {
    //                     alert("Name field is Required")
    //                     focusInput(inputRef1)
    //                 }
    //                 else if (inputValues.address === "") {
    //                     alert("Address field is Required ")
    //                     focusInput(inputRef2)
    //                 }
    //                 else if (inputValues.state === "" || inputValues.state === 0) {
    //                     alert("Please select the state ")
    //                     focusInput(inputRef3)
    //                 }
    //                 else if (inputValues.city === "") {
    //                     alert("City/District feild is required ")
    //                     focusInput(inputRef4)
    //                 }
    //                 else {
    //                     const address = await AddAddress.insertAddress(
    //                         inputValues,
    //                         myAccountID
    //                     );

    //                     if (address) {
    //                         alert("Address added successfully");
    //                         // props.modalclose();
    //                         // props.fetchAddress();
    //                     } else {
    //                         // Handle case when address is not added successfully
    //                     }
    //                 }
    //             } catch (error) {
    //                 console.error("Error inserting data:", error);
    //                 alert("Error inserting data");
    //             }
    //         }
    //     } else {
    //         alert("Validation failed");
    //     }
    // };

    //******************************************************* */

    const fetchAddress = async () => {
        var loginDataString = localStorage.getItem("accountLogin");
        var loginData = JSON.parse(loginDataString);
        console.log(loginData.d1Id);
        setMyAccountID(loginData.d1Id)
        var addresses = await AddAddress.getAddress(loginData.d1Id);
        if (addresses) {
            setAddAddresses(addresses);
            //   setAddressModal(false);
            // setIsModalOpenAddAddress(true);
            setLoading(false);
        } else {
            alert("Error Occoured");
        }
    };

    useEffect(() => {
        const fetchAddress = async () => {
            var loginDataString = localStorage.getItem("accountLogin");
            var loginData = JSON.parse(loginDataString);
            console.log(loginData.d1Id);
            setMyAccountID(loginData.d1Id)
            var addresses = await AddAddress.getAddress(loginData.d1Id);
            if (addresses) {
                setAddAddresses(addresses);
                // setAddressModal(false);
                // setIsModalOpenAddAddress(true);
                setLoading(false);
            } else {
                alert("Error Occoured");
            }
        };

        fetchAddress();
    }, []);

    console.log(addresses);

    //************************Edit Address********************* */

    const editaddress = async (id) => {
        var getaddress = await AddAddress.getAddressById(id, myAccountID);
        // console.log(data);
        console.log(getaddress);
        setEditData(getaddress);
        setIsModalOpenAddAddress(true);
        setShowSelectAddress(false);
    };

    //***************************Delete Address******************************* */

    const deleteaddress = async (id) => {
        if (window.confirm("Are you sure you want to delete this address?")) {
            setLoading(true);
            try {
                var deletedata = await AddAddress.deleteAddress(id, myAccountID);
                if (deletedata) {
                    alert("Address Deleted");
                    fetchAddress();
                } else {
                    alert("Error Occurred");
                }
            } catch (error) {
                alert("Error Occurred: " + error.message);
            } finally {
                setLoading(false);
            }
        }
    };

    const [paymentType, setPaymentType] = useState("");

    const handlePaymentClick = (payType) => {
        setPaymentType(payType);
    }

    const PlaceOrder = async () => {
        const userLogin = localStorage.getItem("accountLogin");
        const userArray = userLogin ? JSON.parse(userLogin) : null;

        const cartData = await Order.getCartList(userArray.d2Id)

        if (paymentType == "") {
            alert("Please select Payment Mode");
        }
        else if (paymentType == "CASH") {
            const accAdd = await AddAddress.getAddress(userArray.d1Id);// userArray.d2Id
            const address = accAdd.find(item => item.isDefault == true);

            const billSeries = await BillSeriesMaster.getBillSeriesMaster();
            const defaultSeries = billSeries.find(item => item.setDefaultSeriesForEcom == true);
            const filteredData = [
                {
                    fieldName: "IsDefault",
                    operatorName: "Equal",
                    compareValue: true
                }
            ]
            const billTypeData = await BillType.GetFilteredBillType(filteredData);
            const billData = { billTypeId: billTypeData ? (billTypeData[0].id || 0) : 0, billSeriesId: defaultSeries ? (defaultSeries?.id || 0) : 0 }

            const data = await Transaction.GetTransRefNoForOrder(userArray, cartData, 214, address, billData); //Cash
            await Order.DeleteEcomCartByAccountId(userArray.d2Id);

            const tabName = "My Order"
            navigate("/admin", {
                state: {
                    tabName
                }
            });
        }
        if (paymentType == "MPESA" || paymentType == "CREDITCARD" || paymentType == "DEBITCARD") {
            const totalAmt = cartData.reduce((total, item) => {
                return total + (item.quantity * item.itemPrice);  // Add the calculated amount for each item
            }, 0);
            // const data = await Transaction.GetTransRefNoForOrder(userArray, cartData, 241); //Online
            // await Order.DeleteEcomCartByAccountId(userArray.d2Id)
            insertPayment(totalAmt); //Transaction will save after payment success
        }
        else {

        }

    }

    return (
        <>  {InputValues.length === 0 ? (
            <div className="black-codesdf">
                <div className="p-graph-head-title">Your shopping cart is empty! </div>
                <Link to="/" className="Blank-cart-page-btn"><button className="pricebox-type-btn-theme3">Continue Shopping</button></Link>
            </div>
        ) : (
            <div>

                <div className="cartpage-high-theme3">
                    <div className="cartpage-left-theme3 cartpage-bottom">
                        <div className="flex-Shipping-address">
                            {
                                navigationBar.map((item) => {
                                    return (
                                        <>
                                            <div onClick={() => !item.disable && item.name != "Summary" ? navigate(item.navigationroute) : null} className={` ${window.location.pathname == item.navigationroute ? "add-color-Shipping-add" : "Top_font-Shipping-Add"} ${item.disable && "disable-Shipping-add"}`}>{item.name}</div>
                                            <div><i class="fa-solid fa-chevron-right icon-margin-Rarrow"></i></div>
                                        </>
                                    )
                                })
                            }

                        </div>
                        <div className="Save-Address-theme3 Select-Address-shipp">Payments Methods</div>
                        <div className="shipping-Address-flex">
                            <div className="Flex-Checkout-powers Power-margin-bottom">
                                <p className="Paragraph-checkout-Font">Power will be taken after checkout. No additional charges for high powers.</p>
                            </div>
                        </div>
                        <div className="shipping-Address-flex">
                            <div className="Flex-Apply-Coupon">
                                <div className="flex-Radio-Coupon">
                                    <div className="Radio-margin-auto-Lc">
                                        <input className="Input-Radio-Coupon" type="radio" name="coupon" onClick={() => toggleSlider()} />
                                    </div>
                                    <div>
                                        <h3 className="Apply-Coupon-Head">Apply Coupon</h3>
                                        <p className="cashbacks-offers">See available offers and cashbacks</p>
                                    </div>
                                </div>
                                <div className="Flex-Angle-right">
                                    <i class="fa-solid fa-angle-right"></i>
                                </div>
                            </div>
                        </div>
                        <div className="Save-Address-theme3 Saved-Add-shipp">Cash on Delivery</div>
                        <div className="shipping-Address-flex">
                            <div className="Flex-Apply-Coupon Upi-Paddin-QR" onClick={() => handlePaymentClick("CASH")}>
                                <div className="flex-UPI-Pay">
                                    <div className="Radio-margin-auto-Lc">
                                        <input className="Input-Radio-Coupon" type="radio" name="coupon" checked={paymentType == "CASH"} />
                                    </div>
                                    <div>
                                        <h3 className="Apply-Coupon-Head">Cash on Delivery</h3>
                                    </div>
                                </div>
                                <div className="Flex-Angle-right">
                                    <i class="fa-solid fa-angle-right"></i>
                                </div>
                            </div>
                        </div>
                        <div className="Save-Address-theme3 Saved-Add-shipp">MPESA</div>
                        <div className="shipping-Address-flex">
                            <div className="Flex-Apply-Coupon Upi-Paddin-QR" onClick={() => handlePaymentClick("MPESA")}>
                                <div className="flex-UPI-Pay">
                                    <div className="Radio-margin-auto-Lc">
                                        <input className="Input-Radio-Coupon" type="radio" name="coupon" checked={paymentType == "MPESA"} />
                                    </div>
                                    <div>
                                        <h3 className="Apply-Coupon-Head">MPESA</h3>
                                    </div>
                                </div>
                                <div className="Flex-Angle-right">
                                    <i class="fa-solid fa-angle-right"></i>
                                </div>
                            </div>
                        </div>

                        {/* <div className="Save-Address-theme3 Saved-Add-shipp">Google Pay</div>
                        <div className="shipping-Address-flex" onClick={insertPayment}>
                            <div className="Flex-Apply-Coupon Upi-Paddin-QR">
                                <div className="flex-UPI-Pay">
                                    <div className="Radio-margin-auto-Lc">
                                        <input className="Input-Radio-Coupon" type="radio" name="coupon" />
                                    </div>
                                    <div className="GooglePay-Img-lc">
                                        <img className="google-Pay-img" src={Googlepay} alt="" />
                                    </div>
                                    <div>
                                        <h3 className="Apply-Coupon-Head">Google Pay</h3>
                                    </div>
                                </div>
                                <div className="Flex-Angle-right">
                                    <i class="fa-solid fa-angle-right"></i>
                                </div>
                            </div>
                        </div> */}

                        {/* <div className="Save-Address-theme3 Saved-Add-shipp">Store Credit</div>
                        <div className="shipping-Address-flex" onClick={insertPayment}>
                            <div className="Flex-Apply-Coupon Upi-Paddin-QR">
                                <div className="flex-UPI-Pay">
                                    <div className="Radio-margin-auto-Lc">
                                        <input className="Input-Radio-Coupon" type="checkbox" name="coupon" />
                                    </div>
                                    <div>
                                        <h3 className="Apply-Coupon-Head">Store Credit</h3>
                                    </div>
                                </div>
                                <div className="Flex-Angle-right">
                                    <i class="fa-solid fa-angle-right"></i>
                                </div>
                            </div>
                        </div> */}

                        {/* <div className="Save-Address-theme3 Saved-Add-shipp">Net Banking</div>
                        <div className="shipping-Address-flex" onClick={insertPayment}>
                            <div className="Flex-Apply-Coupon Upi-Paddin-QR">
                                <div className="flex-UPI-Pay">
                                    <div className="Radio-margin-auto-Lc">
                                        <input className="Input-Radio-Coupon" type="radio" name="coupon" />
                                    </div>
                                    <div>
                                        <h3 className="Apply-Coupon-Head">Net Banking</h3>
                                    </div>
                                </div>
                                <div className="Flex-Angle-right">
                                    <i class="fa-solid fa-angle-right"></i>
                                </div>
                            </div>
                        </div> */}

                        <div className="Save-Address-theme3 Saved-Add-shipp">Credit Card</div>
                        <div className="shipping-Address-flex" onClick={() => handlePaymentClick("CREDITCARD")}>
                            <div className="Flex-Apply-Coupon Upi-Paddin-QR">
                                <div className="flex-UPI-Pay">
                                    <div className="Radio-margin-auto-Lc">
                                        <input className="Input-Radio-Coupon" type="radio" name="coupon" checked={paymentType == "CREDITCARD"}/>
                                    </div>
                                    {/* <div className="GooglePay-Img-lc">
                                        <img className="google-Pay-img" src={PhonePe} alt="" />
                                    </div> */}
                                    <div>
                                        <h3 className="Apply-Coupon-Head">Credit Card</h3>
                                    </div>
                                </div>
                                <div className="Flex-Angle-right">
                                    <i class="fa-solid fa-angle-right"></i>
                                </div>
                            </div>
                        </div>

                        <div className="Save-Address-theme3 Saved-Add-shipp">Debit Card</div>
                        <div className="shipping-Address-flex" onClick={() => handlePaymentClick("DEBITCARD")}>
                            <div className="Flex-Apply-Coupon Upi-Paddin-QR">
                                <div className="flex-UPI-Pay">
                                    <div className="Radio-margin-auto-Lc">
                                        <input className="Input-Radio-Coupon" type="radio" name="coupon" checked={paymentType == "DEBITCARD"}/>
                                    </div>
                                    <div>
                                        <h3 className="Apply-Coupon-Head">Debit Card</h3>
                                    </div>
                                </div>
                                <div className="Flex-Angle-right">
                                    <i class="fa-solid fa-angle-right"></i>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="cartpage-right-theme3">
                        <div style={{ marginLeft: "5px" }} className="cartpage-heading-theme3">Bill Details</div>

                        <div className="">
                            <div className="cartpage-pricebox">

                                <div className="pricebox-type-1">
                                    <div className="pricebox-heading">
                                        Item Total
                                    </div>
                                    <div className="pricebox-heading-2">
                                    {companyData.currencySymbol} {totalPrice}
                                    </div>
                                </div>
                                <div style={{ color: "rgb(17, 186, 216)" }} className="pricebox-type-1">
                                    <div className="pricebox-heading">
                                        Item Coupon
                                    </div>
                                    <div className="pricebox-heading-2">
                                    {companyData.currencySymbol}  0
                                    </div>
                                </div>
                                <div className="pricebox-type-1">
                                    <div className="pricebox-heading">
                                        Net Amount
                                    </div>
                                    <div className="pricebox-heading-2">
                                    {companyData.currencySymbol} {totalPrice}
                                    </div>
                                </div>
                                <div style={{ border: '0' }} className="pricebox-type-1">
                                    <div className="pricebox-heading">
                                        Total payable (incl. taxes)
                                    </div>
                                    <div className="pricebox-heading-2">
                                    {companyData.currencySymbol} {totalPrice}
                                    </div>
                                </div>
                            </div>
                            <div style={{ cursor: "pointer" }} onClick={OpenAddCoupon} className="cartpage-pricebox">
                                <div className="pricebox-1">
                                    <div style={{ display: "flex" }}>
                                        <img src={coupon} alt="" />
                                        <div className="pricebox-coupon">Add Coupon Code</div>
                                    </div>
                                    <i className="fa-solid fa-caret-right"></i>
                                </div>
                            </div>
                            <div>
                                <button className="pricebox-type-btn-theme3" onClick={PlaceOrder}>Place Order</button>
                            </div>
                            {/* <div
                            // onClick={myaccountmodal}
                            // className={`navbar-option ${activeLink === "/MyAccount" ? "active" : ""
                            //     }`}
                            >
                                <button className="pricebox-type-btn-theme3" onClick={SaveBtn}>Proccesed To Payment</button>
                            </div> */}
                        </div>

                    </div>
                    {/* {modal && (
                        <Modal handleClick={signin} />
                    )} */}
                    {/* {alert && (<AlertBox alertname="Remove Item From Cart" close={() => DeleteDeleteEcomCartDetail()} />)} */}
                    {addcoupon && (<AddCouponModal handleClick={OpenAddCoupon} />)}
                    {/* <AlertSuccess /> */}
                </div>

                <div className={`slider-container100 Slider-coupon-width ${isSliderOpen ? "open" : ""}`}>
                    <div style={{ padding: "15px" }}>
                        <div>
                            <div className="slider-display-allow">
                                <div className="slider-head-gm"><i onClick={showModal1Hide} class="fa-solid fa-arrow-left-long slide1-left"></i> <h3 className="slider-top-head-nm">
                                    Apply Coupon </h3></div>
                                <div onClick={toggleSliderClose} className="slider-head-gm"><i class="fa-solid fa-xmark slider-cross-btn"></i></div>
                            </div>
                        </div>


                        <div className="">
                            {showFirstComponent && (
                                <div className={`component first ${transition ? 'exit' : ''}`} onClick={handleClick}>
                                    <div className="buywithlens-content">
                                        <div className="buywithlens-left apply-coupon-width">
                                            <div className="Gaping-Coupon-code">
                                                <div className="Flex-Coupon-code-lc">
                                                    <div className="Coupon-code-width-Lc">
                                                        <input className="Input-Box-Coupon" type="text" placeholder="ENTER COUPON CODE" />
                                                    </div>
                                                    <div className="Apply-CouponBtn-Lc">
                                                        Apply
                                                    </div>
                                                </div>
                                                <div className="Coupons-Moment">
                                                    <div className="Moment-Coupon-alert">
                                                        <span>Sorry! No Coupons available at this moment.</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )}

        </>
    );
};

export default PaymentMethods;
