import React, { useContext, useEffect, useState } from 'react';
import "../../Css/Store.scss";
import MyContext from '../../MyContext';

const AdCard = () => {

const { imageBaseUrl, currentUser, shopBanner } = useContext(MyContext);   
 const [images, setImages] = useState([]);   

    const fetchData = () => {
        if (currentUser === true) {
            try {
                // const data = await ShopBanner.GetShopBanners();
                const data = shopBanner;
                const filteredData = data.filter(item => item.imageBannerName.includes("StorePage")).slice().sort((a, b) => a.bannerPriority - b.bannerPriority);
                console.log("filteredData", filteredData);
                setImages(filteredData);
            } catch (error) {
                console.error('Error fetching shop banners:', error);
            }
        }
    }
    useEffect(() => {
        fetchData();
    }, [currentUser, shopBanner])




    return (
        // <div className="max-w-[730px] w-full flex justify-center">
        //     <div className="ad-card p-4 mt-2 mb-2 rounded-lg w-full">
        //         <img
        //             src={imageUrl}
        //             alt="Ad"
        //             className="w-full h-auto md:h-48 object-cover border rounded-3xl"
        //         />
        //     </div>
        // </div>

        <div className="ad-container">
             {images && images.map((image, index) => (
            <div className="ad-card" key={index}>
                <img 
                    // src={imageUrl}
                    src={`${imageBaseUrl}${image.bannerImageId}`}
                    alt="Ad"
                    className="ad-image"
                />
            </div>
             ))}
        </div>

    );
};

export default AdCard;
