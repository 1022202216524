import { Icon } from '@iconify/react/dist/iconify.js'
import React from 'react'
import "../../../Css/Store.scss";
import { Link } from "react-router-dom";

const BookAppoientment = ({ setmodal, modal }) => {
    if (modal != true) {
        return null
    }

    return (
        <div className="modal-overlay2">
            <div className="modal-content">
                {/* ---top header------ */}
                {/* <div className={`w-full h-auto flex items-start justify-start border-b border-b-[#66668e] py-[15px] flex-col`}>
                    <h1 className={`text-[24px]   text-[#66668e] font-serif pb-[10px] px-[30px] leading-[36px] font-[400]`}>
                        Contact Details
                        <button onClick={() => setmodal(false)} className={`absolute right-0 px-[20px]`}>
                            <Icon icon="akar-icons:cross" style={{ color: "#66668e" }} />
                        </button>
                    </h1>
                    <p className={`text-[14px] font-[400] text-[#66668e] font-serif px-[30px]`}>Write the details of person who will going for Appointment</p>
                </div> */}

                <div className="contact-details">
                    <h1 className="contact-title">
                        Contact Details
                        <button onClick={() => setmodal(false)} className="close-btn">
                            <Icon icon="akar-icons:cross" className="close-icon" />
                        </button>
                    </h1>
                    <p className="contact-description">
                        Write the details of person who will going for Appointment
                    </p>
                </div>


                {/* -----form-------- */}
                {/* <form className={`w-full overflow-hidden h-auto px-[30px] flex flex-col items-center py-[23px] justify-center`}>
                    <div className=' border   mb-[20px] w-full  rounded-md border-[#a2a2b8] px-[30px] py-[7px] relative flex-col items-start justify-center'>
                        <label className={`w-auto text-[15px]   font-serif text-[#66668e]  font-[400] `}>Phone Number</label>
                        <input type='number' className={`h-[30px] number-phone text-[#66668e]  outline-none rounded-md w-full `} />
                    </div>

                    <div className=' border w-full mb-[20px] rounded-md border-[#a2a2b8] px-[30px] py-[7px] relative flex-col items-start justify-center'>
                        <label className={`w-auto text-[15px]   font-serif text-[#66668e]  font-[400] `}>Name</label>
                        <input className={`h-[30px] text-[#66668e]  outline-none rounded-md w-full `} type='text' placeholder='Name' />
                    </div>

                   
                    <div className={`w-[120%] free-reedeem h-auto flex items-center justify-center py-[10px] bg-[#bff6f2]`}>
                        <p className={`py-0 text-[12px] font-[500] text-[#000042] leading-[20px]`}>Redeem FREE ANTI FOG SPRAY on booking!</p>
                    </div>

                   
                    <div className={`w-full py-[22px] h-auto flex items-center justify-center`}>
                        <button className={`w-[100%] text-[#66668e] h-auto py-[12px] flex items-center rounded-[10px] font-[700] justify-center text-[18px] bg-[#dbdbea] `}>
                            Book Appointment

                        </button>
                    </div>
                </form> */}


                <form className="contact-form">
                    <div className="form-group">
                        <label className="form-label">Phone Number</label>
                        <input type="number" className="phone-input" />
                    </div>

                    <div className="form-group">
                        <label className="form-label">Name</label>
                        <input className="name-input" type="text" placeholder="Name" />
                    </div>

                    <div className="free-redeem free-reedeem">
                        <p className="redeem-text">Redeem FREE ANTI FOG SPRAY on booking!</p>
                    </div>

                    <div className="form-submit">
                    <Link to="/bookappointment">
                        <button className="submit-button ">
                            Book Appointment
                        </button>
                        </Link>
                    </div>
                </form>

            </div>

            {/* <div onClick={() => setmodal(false)} className={` fixed z-[30] flex items-center justify-center bg-black opacity-40 top-0 left-0 w-full h-full `} /> */}


            <div onClick={() => setmodal(false)} className="modal-overlay" />

        </div>
    )
}

export default BookAppoientment