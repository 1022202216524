import { Route, Routes, useNavigate } from "react-router-dom";
import "./MyApp/Css/style.scss";
import "./MyApp/Css/Navbar.scss";
import "./MyApp/Css/StyleV.scss";
import "./MyApp/Css/Style_lc.scss";
import "./MyApp/Css/Theme3_LC.scss";
import "./MyApp/Css/Store.scss";
import "./MyApp/Css/Screen1600Plus.scss";
import "./MyApp/Css/tabletcss.scss";
import "./MyApp/Css/mobilecss.scss";
import "./MyApp/Css/Nesting.scss";
import "./MyApp/Css/Ap.css";
import "./MyApp/Css/Draggable.css";
import { useUser, useClerk } from "@clerk/clerk-react";
import HomePage from "./MyApp/Pages/HomePage";
import Footer from "./MyApp/Component/Footer";
import NavBar from "./MyApp/Component/NavBar";
import ProductSearch from "./MyApp/Pages/ProductSearchPage/ProductSearch";
import ProductDetailPage from "./MyApp/Pages/ProductDetailPage/ProductDetailPage";
import CartPage from "./MyApp/Pages/CartPage/CartPage";
import AdminPage from "./MyApp/Pages/AdminPage/AdminPage";
import BuyWithLens from "./MyApp/Pages/BuyWithLensPage/BuyWithLens";
import MobileBottomMentBtn from "./MyApp/Component/Button/MobileBottomMentBtn";
import { useEffect, useRef, useState } from "react";
import FavouritepageMobile from "./MyApp/Pages/MobilePage/FavouritepageMobile";
import ProductDetailPage2 from "./MyApp/Pages/ProductDetailPage/ProductDetailPage2";
import CartPage2 from "./MyApp/Pages/CartPage/CartPage2";
import CartPage3 from "./MyApp/Pages/CartPage/CartPage3";
import ProductDetailPage3 from "./MyApp/Pages/ProductDetailPage/ProductDetailPage3";
import BuyWithLens2 from "./MyApp/Pages/BuyWithLensPage/BuyWithLens2";
import ContactLensDetail from "./MyApp/Pages/ContactLensDetailPage/ContactLensDetail";
import ProductDetailPage5 from "./MyApp/Pages/ProductDetailPage/ProductDetailPage5";
import ProductDetailPage4 from "./MyApp/Pages/ProductDetailPage/ProductDetailPage4";
import ProductDetailPage6 from "./MyApp/Pages/ProductDetailPage/ProductDetailPage6";
import MyContext from "./MyApp/MyContext";
import NotFound from "./MyApp/Pages/NotFound";
import SizeManual from "./MyApp/Pages/GuidePages/SizeManual";
import PrescriptionGuide from "./MyApp/Pages/GuidePages/PrescriptionGuide";
import FaceShapeGuide from "./MyApp/Pages/GuidePages/FaceShapeGuide";
import SquareBoxImage from "./MyApp/Component/Sections/SquareBoxImage";
import ProductSearchWithBanner from "./MyApp/Pages/ProductSearchPage/ProductSearchWithBanner";
import User from "./API/API_SERVISES/User";
import WearTheTrend from "./MyApp/Component/ItemCategoryViewer_LC/WearTheTrend";
import OurPurposeBanner_Lc from "./MyApp/Component/ItemCategoryViewer_LC/OurPurposeBanner_Lc";
import BannerSlider1_Lc from "./MyApp/Component/ItemCategoryViewer_LC/BannerSlider1_Lc";
import Banner1_Lc from "./MyApp/Component/ItemCategoryViewer_LC/Banner1_Lc";
import CarouselLensLc from "./MyApp/Pages/HomepageLc/CarouselLensLc";
import NameWithBanner_Lc from "./MyApp/Component/ItemCategoryViewer_LC/NameWithBanner_Lc";
import ProductList from "./MyApp/Pages/ProductSearchPage/ProductList";
import ProductDetailTheme from "./MyApp/Pages/ProductDetailPage/ProductDetailTheme";
import ProductDetailTheme2 from "./MyApp/Pages/ProductDetailPage/ProductDetailTheme2";
import ProductSliderImages_Lc from "./MyApp/Component/ItemCategoryViewer_LC/ProductSliderImages_Lc";
//import MyAccount from "./MyApp/Pages/AdminPage/MyAccount";
import WishListTheme from "./MyApp/Component/Sections/WishListTheme";
import LoginPanel_Lc from "./MyApp/Component/Modal/LoginPanel_Lc";
import ItemMasterApi from "../src/API/API_SERVISES/ItemMaster";
import MyAccount from "./MyApp/Component/Sections/MyAccount";
import Order from "./API/API_SERVISES/Order";
import TrackOrder from "./MyApp/Component/Sections/TrackOrder";
import ProductDetailTheme1CL from "./MyApp/Pages/ProductDetailPage/ProductDetailTheme1CL";
import Checkout from "./MyApp/Pages/CartPage/Checkout";
import FindThePerfectFit from "./MyApp/Component/ItemCategoryViewer_LC/FindThePerfectFit";
import ContactLenseMore_Lc from "./MyApp/Component/ItemCategoryViewer_LC/ContactLenseMore_Lc";
import ButItYourWay_Lc from "./MyApp/Component/ItemCategoryViewer_LC/ButItYourWay_Lc";
import ShippingAddress from "./MyApp/Pages/CartPage/ShippingAddress";
import CompanyApi from "./API/API_SERVISES/Company";
import PaymentMethods from "./MyApp/Pages/CartPage/PaymentMethods";
import Appointment from "./MyApp/Pages/AppointmentPage/Appointment";
import Bookappoinment from "./MyApp/Pages/AppointmentPage/AppointmentComponents/BookAppointmen/Bookappoinment";
import Phonenumber from "./MyApp/Pages/AppointmentPage/AppointmentComponents/PhoneNumber/Phonenumber";
import Form from "./MyApp/Pages/AppointmentPage/AppointmentComponents/Form/Form";
import CheckOutAndGeneralSetting from "./API/API_SERVISES/CheckoutAndGeneralSetting";
import ItemMaster from "../src/API/API_SERVISES/ItemMaster";
import ProductCart11 from "./MyApp/Component/Card/ProductCart11";
import ShopBanner from "./API/API_SERVISES/Shopbanner";
import ItemGroup from "./API/API_SERVISES/ItemGroup";
import axios from "axios";
import PaymentResponse from "./MyApp/Component/PaymentResponse";
import ContactLenseCart from "./MyApp/Component/Card/ContactLenseCart";
import OrderBox_Lc from "./MyApp/Component/Sections/OrderBox_Lc";
import ProductCart5_lc from "./MyApp/Component/Card/ProductCart5_lc";
import MyPrescription from "./MyApp/Component/Sections/MyPrescription";
import StoreLocation from "./MyApp/Component/StoreLocator/StoreLocation";
import StoreHome from "./MyApp/Component/StoreLocator/StoreHome/StoreHome";
import PartnerWithUs from "./MyApp/Component/PartnerWithUs/PartnerWithUs";
import ContactPage from "./MyApp/Pages/ContactPage/ContactPage";
import DynamicStaticPage from "./MyApp/Component/DynamicPage/DynamicStaticPage";
import KidsGlasses from "./MyApp/Component/KidsGlasses/Kidsglasses";
import MembershipGold from "./MyApp/Component/Membership/MembershipGold";

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;
const ProducrDetailPageURL = "/ProductDetailTheme2";
// const ProducrDetailPageURL = "/ProductDetailTheme"
// const ProducrDetailPageURL = "/ProductDetail"

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

function App() {
  const { user, isSignedIn } = useUser();
  const [logging, setLogging] = useState(false); // For managing login state
  const [error, setError] = useState(null); // For error handling
  const clerk = useClerk(); // Clerk instance
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (user) {
      // Extract user information
      const userInfo = {
        userId: user.id,
        email: user.primaryEmailAddress.emailAddress,
        firstName: user.firstName,
        lastName: user.lastName || "", // Handle case where last name might be null
        createdAt: user.createdAt,
        imageUrl: user.imageUrl,
      };

      setUserData(userInfo); // Store extracted user data
      localStorage.setItem("accountLogin", JSON.stringify(userInfo)); // Optional: Store in localStorage
    }
  }, [user]); // Run this effect when user state changes

  const searchRef = useRef(null);

  const focusInput = (event) => {
    // debugger
    // Check if Alt and S are pressed
    if (event.altKey && event.key === "s") {
      event.preventDefault(); // Prevent the default action if necessary
      if (searchRef.current) {
        searchRef.current.focus(); // Focus the input element
      }
    }
  };

  // Use useEffect to add event listener for keydown
  useEffect(() => {
    window.addEventListener("keydown", focusInput); // Attach event listener

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", focusInput);
    };
  }, []);

  // Function to call your backend API with the extracted user data
  const callMyApiWithUserData = async () => {
    if (!userData) {
      console.error("User data is not available");
      return;
    }

    try {
      const response = await axios.post("http://localhost:5001/myapi", {
        userId: userData.userId, // User's ID
        email: userData.email, // User's email
        firstName: userData.firstName, // User's first name
        lastName: userData.lastName, // User's last name
      });

      console.log("Response from my API:", response.data);
    } catch (err) {
      console.error("Error calling my API:", err);
      setError("Failed to call my API");
    }
  };

  useEffect(() => {
    if (isSignedIn && userData) {
      callMyApiWithUserData(); // Call the API when the user is signed in
    }
  }, [isSignedIn, userData]); // Dependencies to trigger the API call

  // SUGGETION
  const [suggestion, setSuggetion] = useState("");
  const [Itemvariation, setItemvariation] = useState([]);
  console.log(Itemvariation);

  const [DefaultAddressChange, setDefaultAddressChange] = useState(false);
  const [LoginShow, setLoginShow] = useState(true);
  const [myAccount, setMyAccount] = useState(false);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [close, setClose] = useState(true);
  const [checkCredentialsPopup, setCheckCredentialsPopup] = useState(false);
  const [currentUser, setCurrentUser] = useState(false);
  const [currentUserData, setCurrentUserData] = useState([]);
  const [EcomToken, setEcomToken] = useState("");
  const openCredentialModal = () => {
    setCheckCredentialsPopup(!checkCredentialsPopup);
  };
  const [productSearch, setProductSearch] = useState([]);
  const [productlist, setProductList] = useState([]);
  const [wishListItems, setWishListItems] = useState([]);
  const [attributeList, setAttributeList] = useState([]);
  const [wishLisHandle, setwishLisHandle] = useState(false);
  const [wishList, setWishList] = useState([]);
  const [InputValues, setInputValues] = useState([]);
  const [itemDataLens, setItemDataLens] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [myAccountID, setMyAccountID] = useState([]);
  const [companyData, setCompanyData] = useState("");
  const [loginData, setLoginData] = useState({});
  const [inputCartList, setInputCartList] = useState([]);
  const [homeEyeCheckUpData, setHomeEyeCheckUpData] = useState([]);
  const [shopBanner, setShopBanner] = useState([]);
  const [changegrid, setChangegrid] = useState("Tile");
  const fetchShopBannerData = async () => {
    try {
      const data = await ShopBanner.GetShopBanners();
      if (data) {
        setShopBanner(data);
      }
    } catch (error) {
      console.error("Error fetching shop banners:", error);
    }
  };
  useEffect(() => {
    if (currentUser === true) {
      fetchShopBannerData();
    }
  }, [currentUser]);

  console.log(shopBanner);

  const checkCredentials = async () => {
    // debugger
    const data = await User.checkCredentials();
    console.log(data);
    if (data === false) {
      openCredentialModal();
    } else {
      const currentUserData = await User.GetCurrentUser();

      console.log(currentUserData);
      if (currentUserData) {
        setCurrentUserData(currentUserData);
        setCurrentUser(true);
      }
    }
  };

  useEffect(() => {
    checkCredentials();
  }, []);

  // console.log(changegrid);

  useEffect(() => {
    changeProductGrid(changegrid);
  }, [currentUser]);

  const changeProductGrid = (Value) => {
    const gridContainer = document.querySelector(
      ".productSearch-section-wrap3"
    );

    if (window.innerWidth >= 700) {
      // Set grid layout to 3 columns if the screen size is 700px or more
      if (gridContainer) {
        gridContainer.style.gridTemplateColumns = "repeat(3, 1fr)";
      }
      return; // Exit the function
    }

    // Function continues only for screen sizes below 700px

    setChangegrid(Value);
    const elements = document.querySelectorAll(
      ".card-product, .Card-product-type-1, .Card-product-type-11"
    );

    if (gridContainer) {
      // Define the grid layout based on the selected view (Grid or List)
      const isGridView = Value === "Grid";
      const isListView = Value === "List";

      gridContainer.style.gridTemplateColumns = isGridView
        ? "repeat(2, 1fr)"
        : "repeat(1, 1fr)";

      elements.forEach((element) => {
        if (isGridView) {
          element.classList.add("cart-product-for-two-row");
          element.classList.remove("cart-product-for-List");
        } else if (isListView) {
          element.classList.add("cart-product-for-List");
          element.classList.remove("cart-product-for-two-row");
        } else {
          // Tile view (neither Grid nor List)
          element.classList.remove(
            "cart-product-for-two-row",
            "cart-product-for-List"
          );
          gridContainer.style.gridTemplateColumns = "repeat(1, 1fr)";
        }
      });
    }
  };

  useEffect(() => {
    const calculateTotalPrice = async () => {
      if (currentUser === true) {
        let totalPrice = 0;
        let taxPrice = 0;
        let finalPrice = 0;
        let productPrice = 0;
        let productQuantity = 0;
        console.log("InputValues:", InputValues);
        console.log("Type of InputValues:", typeof InputValues);

        if (!Array.isArray(InputValues)) {
          console.error("InputValues is not an array");
          return;
        }
        InputValues.filter((item, index, self) =>
          item.linkedItm == ""
            ? item
            : index === self.findIndex((t) => t.linkedItm === item.linkedItm)
        ).forEach((product) => {
          // totalPrice += (product.itemPrice + product.lensPrice + product.coatingPrice) * product.quantity
          totalPrice +=
            (product.itemPrice + product.lensPrice + product.coatingPrice) *
            product.quantity;
          productPrice += product.itemPrice;
          productQuantity += product.quantity;
          // taxPrice += product.itemPrice * 18 / 100;
          // finalPrice = finalPrice + (product.itemPrice + (product.itemPrice * 18 / 100))
        });
        console.log(finalPrice);
        setTotalPrice(totalPrice);
        // setTaxTotalPrice(taxPrice);
        // setFinalPrice(finalPrice);
        // setProductPrice(productPrice)
        setTotalQuantity(productQuantity);
        // setTotalPrice2(productQuantity)
      }
    };
    calculateTotalPrice();
  }, [InputValues, currentUser]);

  const sendToken = async () => {
    if (EcomToken == "" && EcomToken == undefined) {
      alert("Check the Token and try again");
    } else {
      const data = await User.SendToken(EcomToken);
      if (data == true) {
        alert("Lets go");
        window.location.reload();
      } else {
        alert("Check the Token and try again");
      }
    }
  };

  const handletoken = (e) => {
    var token = e.target.value;
    setEcomToken(token);
  };
  const fetchEyeCheckUpData = async () => {
    if (currentUser === true) {
      try {
        const response =
          await CheckOutAndGeneralSetting.GetEyeCheckUpAndOptiSett();
        console.log(response.result);
        if (response.isSuccess) {
          setHomeEyeCheckUpData(response.result);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (currentUser === true) {
      fetchEyeCheckUpData();
    }
  }, [currentUser]);

  // const myaccountmodal = () => {
  //   setMyAccount(!myAccount);
  //   document.body.style.overflow = myAccount ? "auto" : "hidden";
  //   signupshow();
  // };
  const navigate = useNavigate();
  const userLogin = localStorage.getItem("accountLogin");
  const userArray = userLogin ? JSON.parse(userLogin) : null;

  const myaccountmodal = () => {
    const userLogin = localStorage.getItem("accountLogin");
    const userArray = userLogin ? JSON.parse(userLogin) : null;

    if (userArray) {
      // UserArray is present, navigate to "/MyAccountPage"
      // setMyAccount(!myAccount);
      navigate("/admin");
    } else {
      // UserArray is not present, continue with your existing logic
      setMyAccount(!myAccount);
      document.body.style.overflow = myAccount ? "auto" : "hidden";
      signupshow();
    }
  };

  const [loginPanel, setLoginPanel] = useState(true);
  const [signin, setSignin] = useState(false);
  const [getOtp, setgetOtp] = useState(false);
  const [forgot, setforgot] = useState(false);
  const [ResetPassword, setResetPassword] = useState(false);
  const [itemGroupData, setItemGroupData] = useState([]);

  const signupshow = () => {
    setLoginPanel(true);
    setSignin(false);
    setgetOtp(false);
    setforgot(false);
    setResetPassword(false);
  };
  const ResetPasswordUI = () => {
    setLoginPanel(false);
    setSignin(false);
    setgetOtp(false);
    setforgot(false);
    setResetPassword(true);
  };

  const GoToOTP = async () => {
    setLoginPanel(false);
    setSignin(false);
    setgetOtp(true);
    setforgot(false);
    setResetPassword(false);
  };
  const signupshow1 = () => {
    setLoginPanel(false);
    setSignin(true);
    setgetOtp(false);
    setforgot(false);
    setResetPassword(false);
  };

  const forgotPassword = () => {
    setLoginPanel(false);
    setSignin(false);
    setgetOtp(false);
    setforgot(true);
    setResetPassword(false);
  };

  const [productEnquiry, setProductEnquiry] = useState(false);
  const [searchName, setSearchName] = useState([]);

  const fetchData = async () => {
    if (currentUser === true) {
      // debugger
      try {
        const data = await CompanyApi.GetCompany();
        const data1 = await CompanyApi.GetCompanyById(data[0].id);
        // console.log(data1);
        setCompanyData(data1);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, [currentUser]);

  const fetchItemData = async () => {
    if (currentUser === true) {
      try {
        const categoryName = await ItemMasterApi.GetItemMaster();
        console.log(categoryName);
        setProductList(categoryName);
      } catch (error) {
        console.error("Error Fetching Data:", error);
      }
    }
  };
  useEffect(() => {
    fetchItemData();
  }, [currentUser]);

  // console.log("maxsale price", productlist);

  useEffect(() => {
    const fetchData = async () => {
      if (currentUser === true) {
        // setLoading(true);
        // alert("ig");
        try {
          const categoryName = await ItemGroup.GetItemGroup();
          // console.log("categoryName")
          // console.log(categoryName)
          const categoryData = categoryName
            .filter(
              (item) =>
                item.showInCollection == true && item.imageShowOnError == 2
            )
            .slice()
            .sort((a, b) => a.displayOrder - b.displayOrder);
          setItemGroupData(categoryName);
          // setLoading(false);
        } catch (error) {
          console.error("Error Fetching Data:", error);
        }
      }
    };
    fetchData();
  }, [currentUser]);

  console.log("Item Group Data", itemGroupData);

  const calculateTotalPrice = () => {
    let productQuantity = 0;
    console.log(InputValues);
    if (InputValues.length > 0) {
      InputValues?.forEach((product) => {
        productQuantity += product.quantity;
      });
      setTotalQuantity(productQuantity);
    }
  };
  useEffect(() => {
    if (currentUser == true) {
      calculateTotalPrice();
    }
  }, [InputValues, currentUser]);
  // const [cartData, setCartData] = useState();
  // ***************{ Get Cart Details}********************
  const fetchDataCart = async () => {
    // debugger;
    if (currentUser === true) {
      var loginDataString = localStorage.getItem("accountLogin"); // This is a string
      var loginData = JSON.parse(loginDataString);
      // const LatestCartData = await Order.getCartList(
      //   loginData ? loginData.d2Id || 0 : 0
      // );
      // setCartData(LatestCartData);

      setLoginData(loginData);
      try {
        // const filteredData = [
        //   {
        //     fieldName: "d2AccountId",
        //     operatorName: "equal",
        //     compareValue: loginData ? loginData.d2Id : 0,
        //   },
        // ];
        const val = await Order.getCartList(
          loginData ? loginData.d2Id || 0 : 0
        );
        console.log(val);
        setInputValues(val);
        // setInputCartList(val);
        // calculateTotalPrice()
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    // if (currentUser === true) {
    fetchDataCart();
    // }
  }, [currentUser]);

  const fetchDataWish = async () => {
    // debugger;
    var loginDataString = localStorage.getItem("accountLogin"); // This is a string
    var loginData = JSON.parse(loginDataString);
    if (loginData) {
      setLoginData(loginData);
      const filteredData = [
        {
          fieldName: "d1AccountId",
          operatorName: "equal",
          compareValue: loginData.d1Id,
        },
      ];

      if (currentUser === true) {
        try {
          const data = await Order.getGetAllFavouriteItemList(filteredData);
          setWishList(data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    } else {
      setWishList([]);
    }
  };

  useEffect(() => {
    fetchDataWish();
  }, [currentUser]);

  const myaccountmodal1 = () => {
    setMyAccount(!myAccount);
    document.body.style.overflow = myAccount ? "auto" : "hidden";
  };

  const openproductenquiry = () => {
    setProductEnquiry(!productEnquiry);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const imageBaseUrl = `${WebApiUrl}/api/ItemMaster/GetImage?D1ItemId=`;
  console.log("imageurl", imageBaseUrl);

  const logoCompanyUrl = `${WebApiUrl}/api/v1/Company/Image?ImageId=`;
  console.log("imageurl", logoCompanyUrl);

  const RemoveFromCart = async (item) => {
    // debugger
    var loginDataString = localStorage.getItem("accountLogin"); // This i~s a string
    var loginData = JSON.parse(loginDataString); // Now it's an object
    // if (cartData.length !== 0) {
    //   var quantityFromCartData = cartData.filter(item1 => (item1.d1ItemId === item.d1Id))[0];
    // }
    const data = {
      d1ItemId: item.d1Id || item.d1ItemId,
      d2ItemId: item.d2Id || item.d2ItemId,
      d1AccountId: loginData ? loginData.d1Id || 0 : 0,
      d2AccountId: loginData ? loginData.d2Id || 0 : 0,
      userId: 1,
      quantity: 1,
      unit: "Pcs",
      clientId: 0,
      itemPrice: item.salePrice,
      lensPrice: item.lensPrice,
      lensName: item.lensName,
      coatingPrice: item.coatingPrice,
      coatingName: item.coatingName,

      box_R: item.boxesRight,
      box_L: item.boxesLeft,
      sph_R: item.sphRight,
      sph_L: item.sphLeft,
      cyl_R: item.cylRight,
      cyl_L: item.cylLeft,
      axis_R: item.axisRight,
      axis_L: item.axisLeft,
      add_R: item.addRight,
      add_L: item.addLeft,
      dia_R: item.diaRight,
      dia_L: item.diaLeft,
      bC_R: item.bcRight,
      bC_L: item.bcLeft,
    };
    const isSuccess = await Order.RemoveEcomCartDetail(data);
    if (isSuccess) {
      fetchDataCart();
      // fetchDataCartList();
      setInputValues({
        d1ItemId: " ",
        d2ItemId: " ",
        d1AccountId: " ",
        d2AccountId: " ",
        userId: " ",
        quantity: " ",
        unit: " ",
        itemPrice: " ",
        clientId: "",
        itemPrice: "",
        lensPrice: "",
        lensName: "",
        coatingPrice: "",
        coatingName: "",

        box_R: "",
        box_L: "",
        sph_R: "",
        sph_L: "",
        cyl_R: "",
        cyl_L: "",
        axis_R: "",
        axis_L: "",
        add_R: "",
        add_L: "",
        dia_R: "",
        dia_L: "",
        bC_R: "",
        bC_L: "",
      });
      // calculateTotalAmount();
    }
  };
  // useEffect(() => {
  //   debugger;
  //   fetchVariations();
  // }, [currentUser]);

  const fetchVariations = async (d1Id) => {
    if (currentUser === true) {
      try {
        var data = await ItemMaster.EcomItemsVariation(d1Id);
        setItemvariation(data);
        return data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  return (
    <>
      <MyContext.Provider
        value={{
          productEnquiry,
          imageBaseUrl,
          logoCompanyUrl,
          openproductenquiry,
          myaccountmodal,
          totalQuantity,
          setTotalQuantity,
          setProductSearch,
          productSearch,
          searchName,
          setSearchName,
          productlist,
          setProductList,
          wishListItems,
          setWishListItems,
          attributeList,
          wishLisHandle,
          setwishLisHandle,
          setAttributeList,
          myAccount,
          signupshow1,
          setMyAccount,
          loginPanel,
          signin,
          getOtp,
          GoToOTP,
          signupshow,
          myaccountmodal1,
          setLoginPanel,
          LoginShow,
          setLoginShow,
          forgotPassword,
          forgot,
          setforgot,
          ResetPassword,
          ResetPasswordUI,
          DefaultAddressChange,
          setDefaultAddressChange,
          wishList,
          currentUser,
          setWishList,
          fetchDataWish,
          InputValues,
          setInputValues,
          fetchDataCart,
          fetchDataWish,
          itemDataLens,
          setItemDataLens,
          totalPrice,
          setTotalPrice,
          myAccountID,
          setMyAccountID,
          Itemvariation,
          companyData,
          fetchVariations,
          setCompanyData,
          RemoveFromCart,
          inputCartList,
          setInputCartList,
          homeEyeCheckUpData,
          shopBanner,
          close,
          setClose,
          itemGroupData,
          error,
          changegrid,
          setChangegrid,
          searchRef,
          changeProductGrid,
          ProducrDetailPageURL,
          // handleLogin,
          logging,
          // maxSalePrice,
          //fetchVariations,
          // setMaxSalePrice,
          // fetchDataCartList
        }}
      >
        {wishLisHandle && (
          <div className="fixed-bottom">
            <WishListTheme />
          </div>
        )}
        <NavBar wishList={wishList} />
        <div style={{ width: "minContent" }}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/WishListTheme/:id" element={<WishListTheme />} />
            <Route path="/productsearch" element={<ProductSearch />} />
            <Route path="/productdetail/:id" element={<ProductDetailPage />} />
            <Route path="/pc11" element={<ProductCart11 />} />
            <Route
              path="/productdetail2/:id"
              element={<ProductDetailPage2 />}
            />
            <Route
              path="/productdetail3/:id"
              element={<ProductDetailPage3 />}
            />
            <Route
              path="/productdetail4/:id"
              element={<ProductDetailPage4 />}
            />
            <Route
              path="/productdetail5/:id"
              element={<ProductDetailPage5 />}
            />
            <Route
              path="/productdetail6/:id"
              element={<ProductDetailPage6 />}
            />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/cart2" element={<CartPage2 />} />
            <Route path="/cart3" element={<CartPage3 />} />
            <Route path="/ShippingAddress" element={<ShippingAddress />} />
            <Route path="/PaymentMethods" element={<PaymentMethods />} />
            <Route path="/admin" element={<AdminPage />} />
            <Route path="/buywithlens" element={<BuyWithLens />} />
            <Route path="/buywithlens2" element={<BuyWithLens2 />} />
            <Route path="/fav" element={<FavouritepageMobile />} />
            <Route path="/contactlensdetail" element={<ContactLensDetail />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/SizeManual" element={<SizeManual />} />
            <Route path="/PrescriptionGuide" element={<PrescriptionGuide />} />
            <Route path="/FaceShapeGuide" element={<FaceShapeGuide />} />
            <Route path="/SquareBoxImage" element={<SquareBoxImage />} />
            <Route path="/WearTheTrend" element={<WearTheTrend />} />
            <Route
              path="/OurPurposeBanner_Lc"
              element={<OurPurposeBanner_Lc />}
            />
            <Route path="/TrackOrder" element={<TrackOrder />} />
            <Route path="/FindThePerfectFit" element={<FindThePerfectFit />} />
            <Route
              path="/ContactLenseMore_Lc"
              element={<ContactLenseMore_Lc />}
            />
            <Route path="/ButItYourWay_Lc" element={<ButItYourWay_Lc />} />
            <Route
              path="/ProductDetailTheme1CL/:id"
              element={<ProductDetailTheme1CL />}
            />
            <Route
              path="/OurPurposeBanner_Lc"
              element={<OurPurposeBanner_Lc />}
            />
            <Route path="/BannerSlider1_Lc" element={<BannerSlider1_Lc />} />
            <Route
              path="/ProductSearchWithBanner"
              element={<ProductSearchWithBanner />}
            />
            <Route path="/Banner1_Lc" element={<Banner1_Lc />} />
            <Route path="/CarouselLensLc" element={<CarouselLensLc />} />
            <Route path="/NameWithBanner_Lc" element={<NameWithBanner_Lc />} />
            <Route path="/ProductList" element={<ProductList />} />
            <Route
              path="/ProductDetailTheme/:id"
              element={<ProductDetailTheme />}
            />
            <Route
              path="/ProductDetailTheme2/:id"
              element={<ProductDetailTheme2 />}
            />
            <Route
              path="/ProductSliderImages_Lc"
              element={<ProductSliderImages_Lc />}
            />
            <Route path="/MyAccount" element={<MyAccount />} />

            <Route path="/LoginPanel_Lc" element={<LoginPanel_Lc />} />
            <Route path="/Checkout" element={<Checkout />} />
            <Route path="/bookappointment" element={<Bookappoinment />} />
            <Route path="/phonenumber" element={<Phonenumber />} />
            <Route path="/form" element={<Form />} />
            <Route
              path="/About"
              element={<DynamicStaticPage pageName="About" />}
            />
            <Route
              path="/TermsAndCondition"
              element={
                <div style={{ marginTop: "50px" }}>
                  <DynamicStaticPage pageName="Terms And Condition" />{" "}
                </div>
              }
            />
            <Route
              path="/CancellationPolicy"
              element={<DynamicStaticPage pageName="Cancellation Policy" />}
            />
            <Route
              path="/ReturnPolicy"
              element={<DynamicStaticPage pageName="Return Policy" />}
            />
            <Route
              path="/SecurityAndPrivacyPolicy"
              element={
                <div style={{ marginTop: "50px" }}>
                  <DynamicStaticPage pageName="Security And Privacy Policy" />{" "}
                </div>
              }
            />
            <Route
              path="/ReplacementPolicy"
              element={<DynamicStaticPage pageName="Replacement Policy" />}
            />
            <Route
              path="/ShippingPolicy"
              element={<DynamicStaticPage pageName="Shipping Policy" />}
            />
            <Route
              path="/ContactUs"
              element={<DynamicStaticPage pageName="Contact Us" />}
            />
            <Route
              path="/Disclaimer"
              element={
                <div style={{ marginTop: "50px" }}>
                  <DynamicStaticPage pageName="Disclaimer" />
                </div>
              }
            />
            <Route path="/ContactLenseCart" element={<ContactLenseCart />} />
            <Route path="/PaymentResponse" element={<PaymentResponse />} />
            <Route path="/OrderBox_Lc" element={<OrderBox_Lc />} />
            <Route path="/ProductCart5_lc" element={<ProductCart5_lc />} />
            <Route path="/MyPrescription" element={<MyPrescription />} />
            <Route
              path="/YourFrameSize"
              element={
                <div style={{ marginTop: "50px" }}>
                  <DynamicStaticPage pageName="Your Frame Size" />
                </div>
              }
            />
            <Route
              path="/Blogs"
              element={
                <div style={{ marginTop: "50px" }}>
                  <DynamicStaticPage pageName="Blogs" />
                </div>
              }
            />
            <Route
              path="/FAQ"
              element={
                <div style={{ marginTop: "50px" }}>
                  <DynamicStaticPage pageName="FAQ" />
                </div>
              }
            />
            <Route
              path="/QuickGuide"
              element={<DynamicStaticPage pageName="Quick Guide" />}
            />
            <Route
              path="/MeasureYourPD"
              element={
                <div style={{ marginTop: "50px" }}>
                  <DynamicStaticPage pageName="Measure Your PD" />
                </div>
              }
            />
            <Route
              path="/VirtualTryOnTool"
              element={
                <div style={{ marginTop: "50px" }}>
                  <DynamicStaticPage pageName="Virtual Try On Tool" />
                </div>
              }
            />
            <Route
              path="/ReadYourPrescription"
              element={
                <div style={{ marginTop: "50px" }}>
                  <DynamicStaticPage pageName="Read Your Prescription" />
                </div>
              }
            />
            <Route
              path="/Partner"
              element={<DynamicStaticPage pageName="Partner" />}
            />
            <Route
              path="/Landlord"
              element={<DynamicStaticPage pageName="Landlord" />}
            />
            <Route path="/Storelocator" element={<StoreLocation />} />
            <Route path="/Storehome/:id" element={<StoreHome />} />
            <Route path="/partnerwithus" element={<PartnerWithUs />} />
            <Route path="/contactpage" element={<ContactPage />} />
            <Route
              path="/FrameMaterial"
              element={
                <div style={{ marginTop: "50px" }}>
                  <DynamicStaticPage pageName="Frame Material" />
                </div>
              }
            />
            <Route
              path="/WarrantyPolicy"
              element={
                <div style={{ marginTop: "50px" }}>
                  <DynamicStaticPage pageName="Warranty Policy" />{" "}
                </div>
              }
            />
            <Route
              path="/ForEyeglasses"
              element={
                <div style={{ marginTop: "50px" }}>
                  <DynamicStaticPage pageName="For Eyeglasses" />{" "}
                </div>
              }
            />
            
            <Route path="/KidsGlasses" element={<KidsGlasses />} />
      <Route path="/MembershipGold" element={<MembershipGold />} />

            {/* <Route path="/PaymentResponse/:Id" element={<PaymentResponse />} /> */}
          </Routes>
        </div>
        <MobileBottomMentBtn />
        <div style={{ background: "#000042" }}>
          <DynamicStaticPage pageName="Footer" />
        </div>
        {/* <Footer_lc /> */}
        {myAccount && <MyAccount close={myaccountmodal} />}

        {checkCredentialsPopup && (
          <div className="modal-back">
            <div
              className="account-modal"
              style={{
                height: "120px",
                flexDirection: "column",
                paddingBottom: "10px",
              }}
            >
              <div
                className="input-group"
                style={{ width: "90%", margin: "auto 0" }}
              >
                <label htmlFor="username">Enter Token</label>
                <input
                  type="text"
                  onChange={(e) => handletoken(e)}
                  id="username"
                  placeholder=""
                  name=""
                />
              </div>
              <button
                style={{
                  padding: "5px 20px",
                  border: "none",
                  borderRadius: "5px",
                  backgroundColor: "rgba(167, 139, 250, 1)",
                }}
                onClick={sendToken}
              >
                {" "}
                Submit{" "}
              </button>
            </div>
          </div>
        )}
      </MyContext.Provider>
    </>
  );
}

export default App;
