import React, { useState } from 'react'
import delhi from "../../../Assets/Image_lc/Delhi1.webp"
import mumbao from "../../../Assets/Image_lc/Mumbai1.webp"
import banglore from "../../../Assets/Image_lc/Banglore1.webp"
import chennai from "../../../Assets/Image_lc/Chennai1.webp"
import hyderabad from "../../../Assets/Image_lc/Hyderabad1.webp"
import ahmedabad from "../../../Assets/Image_lc/Ahmedabad1.webp"
import cities from "../../../Assets/Image_lc/Cities1.webp"
import { useNavigate } from 'react-router-dom'
import "../../../Css/Store.scss";



const Storelocator = () => {
    const [togle, settogle] = useState(false)
    const navigate = useNavigate()
    const togglefnc = () => {
        settogle(!togle)
    }

    // const countrydata = [
    //     {
    //         name: "Delhi",
    //         span: delhi,
    //         navigate: "/Storelocator"
    //     },
    //     {
    //         name: "Mumbai",
    //         span: mumbao,
    //         navigate: "/Storelocator"
    //     },
    //     {
    //         name: "Bangalore",
    //         span: banglore,
    //         navigate: "/Storelocator"
    //     },
    //     {
    //         name: "Chennai",
    //         span: chennai,
    //         navigate: "/Storelocator"
    //     },
    //     {
    //         name: "Ahmedabad",
    //         span: ahmedabad,
    //         navigate: "/Storelocator"
    //     },
    //     {
    //         name: "Hyderabard",
    //         span: hyderabad,
    //         navigate: "/Storelocator"
    //     },
    //     {
    //         name: "1000+ Cities",
    //         span: cities,
    //         navigate: "/Storelocator"
    //     },
    // ]

    return (
        <div className="storelocator-container">
            <div className="storelocator-content" onMouseEnter={() => settogle(true)} onMouseLeave={() => settogle(false)}>
                {/* ----------left--------------- */}
                <div className="storelocator-left">
                    <div className="storelocator-left-text">
                        <h1>Lens2cart Your One Stop Shop For Eyewear Perfection</h1>
                        <p>Experience eyewear in a whole new way: Visit Your nearest store</p>
                        <p>and treat yourself to 5000+ eyewear styles.</p>
                    </div>

                    <div className="storelocator-button-container">
                        <button onClick={() => navigate("/Storelocator")} className="storelocator-button">
                            Locate a Store
                        </button>
                    </div>
                </div>
                {/* --------------right------------------- */}
                {/* <div className="storelocator-right">
                    {
                        countrydata.map((item, index) => {
                            return (
                                <div key={index} onClick={() => navigate(item.navigate)} className="storelocator-item">
                                    <img src={item.span} alt={item.name} />
                                    <p className='location-name-store'>{item.name}</p>
                                </div>
                            )
                        })
                    }
                </div> */}
            </div>
        </div>
    )
}

export default Storelocator
