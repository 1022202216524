import React from 'react'

const WelcomePOPUP = ({ setshowwelcompop, showwelcomepop }) => {
    return (
        <div className={`main-welcomepop`}>
            <div className={` welcome-pop-up`}>
                <div className={`wl-pop-h1`}>
                    <h1 className={``}>Would you like to receieve Push Notification?</h1>
                </div>
                <div className={`wl-pop-subline`}>
                    <h3>We promise to send you relevent content and give updates on your transaction</h3>
                </div>
                <div className={`wl-pop-button`}>
                    <button onClick={() => setshowwelcompop(false)} className={`wl-signup-no`}>No thanks!</button>
                    <button className={`wl-singup`}>Sign me Up!</button>
                </div>
            </div>
            <div onClick={() => setshowwelcompop(false)} className={`welcomepop-back`}></div>
        </div>
    )
}

export default WelcomePOPUP